<template>
    <div class="ipt-button" :class="{
        [this.mapSizeToClass[this.styles.size]]: true,
        [this.class]: true,
    }">
        <spin-loader class="button_loading" v-if="isLoading"
            :sizeSpinner="mapSizeToLoaderSize[styles.size]"
            :colorSpinner="mapTypeToLoaderColor[styles.type]">
        </spin-loader>
        <div v-if="$slots.iconLeft"
            class="button-icon button-icon-left"
            :class="{'invisible': isLoading}">
                <slot name="iconLeft"></slot>
        </div>
        <div :class="isLoading ? 'button-text_loading' : 'button-text'">
            <slot></slot>
        </div>
        <div v-if="$slots.iconRight"
            class="button-icon button-icon-right"
            :class="{'invisible': isLoading}">
            <slot name="iconRight"></slot>
        </div>
    </div>

</template>

<script>
import SpinLoader from '@/components/UI/loaders/SpinLoader.vue';
export default {
    name: "IptButton",
    components: {
        SpinLoader
    },
    props: {
        styles: {
            "type": {
                type: String, //backgrond-color color
            },
            size: String, //padding gap size font-size
            state: String, //backgrond-color color
        },
        isLoading: {
            type: Boolean, 
            default: false
        }
    },
    data() {
        const mapTypeToClass = {
            "Primary": "primary",
            "Primary Light": "primary-light",
            "Secondary Grey": "secondary-grey",
            "Secondary": "secondary",
            "Dark Grey": "dark-grey",
            "Tertiary": "tertiary",
            "Link Color": "link-color delete-padding",
            "Link Grey": "link-grey delete-padding",
        };
        const mapSizeToClass = { // классы шрифтов + padding + size
            "xsm": "font-paragraph-5-medium size-xsm",
            "sm": "font-paragraph-5-medium size-sm",
            "md": "font-paragraph-4-medium size-md",
            "lg": "font-paragraph-4-medium size-lg",
            "xl": "font-paragraph-3-medium size-xl",
        };
        const mapSizeToLoaderSize = {
            "xsm": "8px",
            "sm": "10px",
            "md": "12px",
            "lg": "15px",
            "xl": "16px",
        };
        const mapTypeToLoaderColor  = {
            "Primary": "var(--color-base-00)",
            "Primary Light": "var(--color-primary-blue-500)",
            "Secondary Grey": "var(--color-state-grey-600)",
            "Secondary": "var(--color-primary-blue-500)",
            "Dark Grey": "var(--color-state-grey-600)",
            "Tertiary": "var(--color-state-grey-600)",
            "Link Color": "var(--color-state-grey-600)",
            "Link Grey": "var(--color-primary-blue-500)",
        }
        const mapStateToClass = {
            "disabled": "_disabled"
        };
        return {
            mapTypeToClass, mapSizeToClass, mapStateToClass, mapSizeToLoaderSize, mapTypeToLoaderColor
        }
    },
    computed: {
        class(){
            return this.mapStateToClass[this.styles.state] ? `${this.mapTypeToClass[this.styles.type]}`+this.mapStateToClass[this.styles.state] : this.mapTypeToClass[this.styles.type]
        },
    }

}
</script>

<style scoped>
.invisible{
    visibility: hidden;
}
.svg-icon{
    overflow: visible;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.button-icon{
    display: flex;
    align-items: center;
    justify-content: center;
}
.button-icon-left, .button-icon-right{
    position: relative;
}
.ipt-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    position: relative;
}
.button_loading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.button-text_loading{
    color: transparent;
}
/* primary */
.primary {
    background-color: var(--color-primary-blue-500);
    color: var(--color-base-00);
}

.primary:hover {
    background-color: var(--color-primary-blue-600);
}

.primary:focus {
    background-color: var(--color-primary-blue-500);
    box-shadow: 0px 0px 0px 3px rgba(232, 237, 255, 1);
    box-shadow: 0px 1px 2px 0px rgba(232, 237, 255, 1);
}

.primary:active {
    background-color: var(--color-primary-blue-600);
}

.primary_disabled {
    background-color: var(--color-primary-blue-100);
    color: var(--color-base-00);
    cursor: not-allowed;
}

/* primary-light */
.primary-light {
    background-color: var(--color-state-grey-25);
    color: var(--color-primary-blue-500);
}

.primary-light:hover {
    background-color: var(--color-state-grey-50);
}

.primary-light:focus {
    background-color: var(--color-state-grey-25);
    box-shadow: 0px 0px 0px 3px rgba(232, 237, 255, 1);
    box-shadow: 0px 1px 2px 0px rgba(232, 237, 255, 1);
}

.primary-light:active {
    background-color: var(--color-state-grey-50);
}

.primary-light_disabled {
    background-color: var(--color-state-grey-25);
    color: var(--color-primary-blue-100);
}

/* secondary-grey */
.secondary-grey {
    background-color: var(--color-base-00);
    color: var(--color-state-grey-600);
    border: 1px solid var(--color-state-grey-200);
}

.secondary-grey:hover {
    background-color: var(--color-state-grey-200);
    box-shadow: 0px 1px 2px 0px rgba(45, 54, 67, 0.05);
}

.secondary-grey:focus {
    background-color: var(--color-state-grey-25);
    box-shadow: 0px 0px 0px 3px rgba(240, 243, 249, 1);
    box-shadow: 0px 1px 2px 0px rgba(240, 243, 249, 1);
}

.secondary-grey:active {
    background-color: var(--color-state-grey-25);
}

.secondary-grey_disabled {
    background-color: var(--color-state-grey-25);
    color: var(--color-state-grey-200);
    cursor: not-allowed;
}

/* secondary */
.secondary{
    background-color: var(--color-base-00);
    border: 1px solid var(--color-primary-blue-400);
    color: var(--color-primary-blue-500);
}
.secondary:hover {
    background-color: var(--color-state-grey-25);
    color: var(--color-primary-blue-500);
    border: 1px solid var(--color-primary-blue-400);
    box-shadow: 0px 1px 2px 0px rgba(45, 54, 67, 0.05);
}
.secondary:focus {
    background-color: var(--color-base-00);
    color: var(--color-primary-blue-500);
    border: 1px solid var(--color-primary-blue-400);
    box-shadow: 0px 0px 0px 3px rgba(232, 237, 255, 1);
    box-shadow: 0px 1px 2px 0px rgba(232, 237, 255, 1);
}
.secondary:active {
    background-color: var(--color-state-grey-25);
    color: var(--color-primary-blue-500);
    box-shadow: 0px 1px 2px 0px rgba(45, 54, 67, 0.05);
    border: 1px solid var(--color-primary-blue-400);
}
.secondary_disabled {
    background-color: var(--color-base-00);
    color: var(--color-primary-blue-200);
    border: 1px solid var(--color-primary-blue-100);
    cursor: not-allowed;
}
/* dark-grey */
.dark-grey {
    background-color: var(--color-state-grey-200);
    color: var(--color-state-grey-600);
}
.dark-grey:hover {
    background-color: var(--color-state-grey-300);
    color: var(--color-state-grey-600);
    box-shadow: 0px 1px 2px 0px rgba(45, 54, 67, 0.05);
}
.dark-grey:focus {
    background-color: var(--color-state-grey-200);
    color: var(--color-state-grey-600);
    box-shadow: 0px 0px 0px 3px rgba(240, 243, 249, 1);
    box-shadow: 0px 1px 2px 0px rgba(240, 243, 249, 1);
}
.dark-grey:active {
    background-color: var(--color-state-grey-300);
    color: var(--color-state-grey-600);
    box-shadow: 0px 1px 2px 0px rgba(45, 54, 67, 0.05);
}
.dark-grey:disabled {
    background-color: var(--color-state-grey-100);
    color: var(--color-state-grey-200);
}
/* tertiary */
.tertiary {
    background-color: var(--color-base-00);
    color: var(--color-state-grey-600);
}
.tertiary:hover {
    background-color: var(--color-state-grey-25);
    color: var(--color-primary-blue-500);
    box-shadow: 0px 1px 2px 0px rgba(45, 54, 67, 0.05);
}
.tertiary:focus {
    background-color: var(--color-base-00);
    color: var(--color-primary-blue-500);
}
.tertiary:active {
    background-color: var(--color-state-grey-25);
    color: var(--color-primary-blue-500);
    box-shadow: 0px 1px 2px 0px rgba(45, 54, 67, 0.05);
    box-shadow: 0px 1px 2px 0px rgba(45, 54, 67, 0.05);
}
.tertiary_disabled {
    background-color: var(--color-base-00);
    color: var(--color-state-grey-200);
    cursor: not-allowed;
}
/* link-grey */
.link-grey {
    color: var(--color-state-grey-600);
}
.link-grey:hover {
    color: var(--color-state-grey-700);
}
.link-grey:focus {
    color: var(--color-state-grey-900);
}
.link-grey:active {
    color: var(--color-state-grey-700);
}
.link-grey:disabled {
    color: var(--color-state-grey-200);
}
/* link-color */
.link-color{
    color: var(--color-primary-blue-500);
}
.link-color:hover{
    color: var(--color-primary-blue-400);
}
.link-color:focus{
    color: var(--color-primary-blue-600);
}
.link-color:active{
    color: var(--color-primary-blue-400);
}
.link-color:disabled{
    color: var(--color-primary-blue-200);
}
/* size-block */
.size-xsm {
    height: 36px;
    padding: 6px 12px;
    border-radius: 8px;
    /* gap: 8px; */
    white-space: nowrap;
}

.size-sm {
    padding: 8px 15px;
    border-radius: 10px;
    gap: 10px;
}

.size-md {
    padding: 10px 20px;
    border-radius: 10px;
    gap: 10px;
}

.size-lg {
    padding: 13px 20px;
    border-radius: 10px;
    gap: 10px;
}

.size-xl {
    padding: 14px 22px;
    border-radius: 10px;
    gap: 10px;
}
.delete-padding{
    padding: 0px;
}
</style>