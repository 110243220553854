import CodeService from '@/services/CodeService.js';
import latexStatusToText from '@/models/latex/latexStatuses.json';

import { promiseTimeout } from '@/myLib/decorators';

class LatexHandler {
    /**
    * @param guid id карточки
    * @param path путь свойства
    */  
    constructor(guid, path, latexStatus){
        this.guid = String(guid);
        this.path = String(path);

        this.latexStatus = String(latexStatus);
        this.statusGuid = '';
        this.generationProcess = {};

        this.code = '';
        this.linkPDF = '';
        this.error = {};
    }
    getGuid(){
        return this.guid;
    }
    getPath(){
        return this.path;
    }
    getStatusGuid(){
        return this.statusGuid;
    }
    getLinkPDF(){
        return this.linkPDF;
    }
    getLatexStatus(){
        return this.latexStatus;
    }

    setStatusGuid(statusGuid){
        this.statusGuid = statusGuid;
    }
    setLatexStatus(status){
        this.latexStatus = status;
    }
    setGenerationProcess(objProcess){
        this.generationProcess = objProcess;
    }

    isValidStatusToDownloadPDF(){
        return this.latexStatus === 'Completed';
    }

    updatePDFLink(){//успловие проверки мэйби вынести
        if(!this.isValidStatusToDownloadPDF()) return Promise.resolve(null);
        const objToSend = {
            id: this.getGuid(),
            path: this.getPath(),
        }
        return CodeService.GetLinkCode(objToSend)
        .then(r=>{
            this.linkPDF = String(r.data);
        })
        .catch((e)=>{
            if(e?.response?.status === 400){
                this.setLatexStatus('emptyFile');
                return;
            }
        });
    }
    getStatusText(){
        let statusText = latexStatusToText[this.latexStatus] || latexStatusToText['errorService'];
        if(this.generationProcess && this.generationProcess.totalJobs){
            statusText = statusText + ' ' +
                this.generationProcess.completedJobs + 
                ' / ' + this.generationProcess.totalJobs;
        }
        return statusText;
    }

    handleUpdatingStatus(options){
        return this.updateStatusGuid()
        .then((data)=>{
            if(!data) {
                this.setLatexStatus('emptyLatex');
                return;
            }
            return this.updateStatus(options);
        })
    }
    updateStatusGuid(){
        const objToSend = {
            id: this.getGuid(),
            path: this.getPath(),
        }
        return CodeService.CheckCode(objToSend)
        .then(r=>{
            const data = r.data;
            this.setStatusGuid(data);
            return data;
        })
    }
    /**
    * @param optionsObj {signal: AbortSignal}
    */  
    async updateStatus(optionsObj) {
        const guid =  this.getGuid();
        const path = this.getPath();
        const statusGuid = this.getStatusGuid();

        const isValide = guid 
            && path 
            && statusGuid;
        if(!isValide) {
            const obj = {
                guid: guid,
                path: path,
                statusGuid: statusGuid
            }
            throw new Error('class: LatexHandler -> updateStatus: неверные исходные данные' + JSON.stringify(obj));
        }
            
        const objToSendInfo = {
            guid: guid,
            path: path,
            status: statusGuid,
            options: optionsObj
        }

        let isCompleted = false;
        while(!isCompleted){
            const resp = await CodeService.GetStatusInfo(objToSendInfo)
            .catch(e=>{
                if(!e || e.response?.status === 400){
                    return null;
                }
            });
            if(!resp) return;

            const data = resp.data;
            this.setLatexStatus(data.status);
            this.setGenerationProcess(data.currentProcess || {});
            
            if(data.fromDynamic === true){
                isCompleted = true;
                break;
            }
            await promiseTimeout(500);
        }
    }
}
export { LatexHandler }