import apiConfig from "@/config/api.config.js";
import $api from "@/http";
import {ValidationObject} from '@/myLib/validationFields';
export default class ObjectService extends ValidationObject{ //получить объекты 
    static apiPrefix = apiConfig.API_GATEWAY + '/v2'
    static GetObjects(obj) {
        const allowedParams = ['ExtremumDateTime', 'Down', 'Size', 'type', 'sort', 'search', 'filter'];
        const params = ObjectService.getObjectByValidKeys(allowedParams, obj);
        return $api.get(`${ObjectService.apiPrefix}/Object/GetObjects`, 
            { params }
        );
    }
    static GetSearchObjects(obj){
        const allowedParams = ['type', 'search', 'Size'];
        const query = ObjectService.getObjectByValidKeys(allowedParams, obj);
        return $api.get(`${ObjectService.apiPrefix}/Object/GetSearchObjects`, 
            { params: query }
        );
    }
    static GetListSearchObjects(obj){
        const allowedParams = ['type', 'search', 'Size', 'ExtremumDateTime'];
        const query = ObjectService.getObjectByValidKeys(allowedParams, obj);
        return $api.get(`${ObjectService.apiPrefix}/Object/GetListSearchObjects`, 
            { params: query }
        );
    }
    static GetObject(obj){ //systemInfoModule
        const allowedUrlParams = ['id'];
        const urlParamsString = ObjectService.getStringByValidKeys(allowedUrlParams, obj, '/');
        return $api.get(`${ObjectService.apiPrefix}/Object/GetObject/${urlParamsString}`);
    }
    static GetParameter(obj){
        const allowedUrlParams = ['id', 'path'];
        const urlParamsString = ObjectService.getStringByValidKeys(allowedUrlParams, obj, '/');
        return $api.get(`${ObjectService.apiPrefix}/Object/GetParameter/${urlParamsString}`);
    }
    static GetProperties(obj){ //IptDropdown--legacy systemInfoModule systemsCards--legacy
        const allowedQuery = ['PropertyName'];
        const query = ObjectService.getObjectByValidKeys(allowedQuery, obj);
        const allowedUrlParams = ['id'];
        const urlParamsString = ObjectService.getStringByValidKeys(allowedUrlParams, obj, '/');
        return $api.get(`${ObjectService.apiPrefix}/Object/GetProperties/${urlParamsString}`,
            { params: query }
        );
    }
    static GetFile(obj){//SimpleFiles -> Возращает ссылку на файл
        const allowedQuery = ['technicalFileName', 'userFileName'];
        const query = ObjectService.getObjectByValidKeys(allowedQuery, obj);
        const allowedUrlParams = ['id', 'path'];
        const urlParamsString = ObjectService.getStringByValidKeys(allowedUrlParams, obj, '/');
        return $api.get(`${ObjectService.apiPrefix}/Object/GetFile/${urlParamsString}`,
            { params: query }
        );
    }
    static PostFile(obj){//FilesInput
        const allowedUrlParams = ['id', 'path'];
        const urlParamsString = ObjectService.getStringByValidKeys(allowedUrlParams, obj, '/');
        return $api.post(`${ObjectService.apiPrefix}/Object/PostFile/${urlParamsString}`,
            obj.formData,
            obj.options
        );
    }
    static AddCollectionElement(obj){//CollectionInput}
        const allowedUrlParams = ['id', 'path'];
        const urlParamsString = ObjectService.getStringByValidKeys(allowedUrlParams, obj, '/');
        return $api.post(`${ObjectService.apiPrefix}/Object/AddCollectionElement/${urlParamsString}`);
    }
    static CreateObject(obj){
        const allowedParams = ['typeObject'];
        const query = ObjectService.getObjectByValidKeys(allowedParams, obj);
        return $api.post(`${ObjectService.apiPrefix}/Object/CreateObject`, 
            { parameters: obj.parameters }, 
            { params: query }
        )
    }
    static ExpressionsAdd(obj){
        const queryParams = ['typeObject'];
        const query = ObjectService.getObjectByValidKeys(queryParams, obj);
        const allowedParams = ['searchBy', 'comparisons'];
        const objBody = ObjectService.getObjectByValidKeys(allowedParams, obj);
        objBody.searchBy = objBody.searchBy ? JSON.stringify(objBody.searchBy) : null;
        if(!objBody.searchBy) delete objBody.searchBy;
        objBody["comparisons"] = objBody.comparisons
        .filter(it=>Boolean(it.value))
        .map(it=>{
            if(it.typeFilter == 'Enum'){
                it.value = `"${it.value}"`
            }
            const objFilter = {
                "path": it.path,
                "value": it.value,
                "operator": it.operator
            }
            return objFilter;
        });
        if(objBody.comparisons.length == 0){
            delete objBody.comparisons;
        }
        return $api.post(`${ObjectService.apiPrefix}/Object/ExpressionsAdd`,
            objBody,
            { params: query }
        );
    }
    static PutValue(obj){//inputsHandler changeParam+
        const allowedUrlParams = ['id', 'path'];
        const urlParamsString = ObjectService.getStringByValidKeys(allowedUrlParams, obj, '/');
        return $api.put(`${ObjectService.apiPrefix}/Object/PutValue/${urlParamsString}`,
            { data: obj.data }
        );
    }
    // static PutCode(obj){ //LatexCode PageDocsTEmplate changeParam+
    //     const allowedUrlParams = ['id', 'path'];
    //     const urlParamsString = ObjectService.getStringByValidKeys(allowedUrlParams, obj, '/');
    //     return $api.put(`${ObjectService.apiPrefix}/Object/PutCode/${urlParamsString}`,
    //         { data: obj.data }
    //     );
    // }
    static DeleteFile(obj){ //FilesInput
        const allowedQuery = ['technicalFileName', 'userFileName'];
        const query = ObjectService.getObjectByValidKeys(allowedQuery, obj);
        const allowedUrlParams = ['id', 'path'];
        const urlParamsString = ObjectService.getStringByValidKeys(allowedUrlParams, obj, '/');
        return $api.delete(`${ObjectService.apiPrefix}/Object/DeleteFile/${urlParamsString}`,
            { params: query }
        );
    }
    static DeleteCollectionElement(obj){ //CollectionInput changeParam+
        const allowedUrlParams = ['id', 'path', 'index'];
        const urlParamsString = ObjectService.getStringByValidKeys(allowedUrlParams, obj, '/');
        return $api.delete(`${ObjectService.apiPrefix}/Object/DeleteCollectionElement/${urlParamsString}`,
            { data: obj.data }
        );
    }
    static ExpressionsClear(obj){
        const queryParams = ['typeObject'];
        const query = ObjectService.getObjectByValidKeys(queryParams, obj);
        return $api.delete(`${ObjectService.apiPrefix}/Object/ExpressionsClear`,
            { params: query }
        );
    }
    static GetExpressions(obj){
        const allowedHeaders = ['type'];
        const headers = ObjectService.getObjectByValidKeys(allowedHeaders, obj);
        const queryParams = ['typeObject'];
        const query = ObjectService.getObjectByValidKeys(queryParams, obj);
        return $api.get(`${ObjectService.apiPrefix}/Object/GetExpressions`, 
            {
                headers: headers,
                params: query
            }
        );
    }
    static GetConnectionTo(obj){ //only v2
        const queryParams = ['instanceId'];
        const query = ObjectService.getObjectByValidKeys(queryParams, obj);
        return $api.get(`${ObjectService.apiPrefix}/Object/GetConnectionTo`, 
            { params: query }
        );
    }
    // static GetLatexCode(obj){
    //     const allowedUrlParams = ['id', 'path'];
    //     const urlParamsString = ObjectService.getStringByValidKeys(allowedUrlParams, obj, '/');
    //     return $api.get(`${ObjectService.apiPrefix}/Object/GetLatexCode/${urlParamsString}`);
    // }
    // static GetLinkCode(obj){
    //     const allowedQueryParams = ['YellowText'];
    //     const query = ObjectService.getObjectByValidKeys(allowedQueryParams, obj);
    //     const allowedUrlParams = ['id', 'path'];
    //     const urlParamsString = ObjectService.getStringByValidKeys(allowedUrlParams, obj, '/');
    //     return $api.get(`${ObjectService.apiPrefix}/Object/GetLinkCode/${urlParamsString}`,
    //         { params: query }
    //     );
    // }
    // static CheckCode(obj){
    //     const allowedUrlParams = ['id', 'path'];
    //     const urlParamsString = ObjectService.getStringByValidKeys(allowedUrlParams, obj, '/');
    //     return $api.get(`${ObjectService.apiPrefix}/Object/CheckCode/${urlParamsString}`);
    // }
}