<template>
    <div class="ipt-tooltip"
        :class="{[mapPositionMarkerToClass[positionMarker]] : true}">
        <div class="ipt-tooltip__content font-paragraph-6-medium text-overflow-ellipsis">
            {{ text }}
        </div>
    </div>
</template>

<script>
export default {
    name: "TooltipSingleLine",
    props:{
        text: String,
        positionMarker: String,
    }, 
    data(){
        const mapPositionMarkerToClass = {
            "bottom-right" : "",
            "bottom-center" : "bottom-center",
            "bottom-left" : "",
            "top-right" : "",
            "top-center" : "",
            "top-left" : "top-left",
            "left-side" : "left-side",
            "right-side" : "right-side",
        };
        return{
            mapPositionMarkerToClass
        }
    }
}
</script>

<style scoped>
.ipt-tooltip{
    position: relative;
    width: fit-content;
    padding: 8px 12px;
    border-radius: 6px;
    background-color: var(--color-state-grey-100);
    max-width: 400px;
}
.ipt-tooltip__content{
    color: var(--color-state-grey-600);
    white-space: nowrap;
}
.top-left::after{
    content: "";
    position: absolute;
    bottom: 100%;
    left: 24px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--color-state-grey-100) transparent;
}
.bottom-center::after{
    content: " ";
    position: absolute;
    top: 100%; 
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--color-state-grey-100) transparent transparent transparent;
}
.left-side::after{
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent var(--color-state-grey-100) transparent transparent;
}
.right-side::after{
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%; /* To the right of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent var(--color-state-grey-100);
}
</style>