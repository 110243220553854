import { version } from '@/../package.json';
const versionHandler = {
    getVersion: () => {
        return version;
    },
    isVersionActual: (version) => {
        return version === versionHandler.getVersion();
    },
    getVersionFromLS(){
        return localStorage.getItem('last-version-iptimizer');
    },
    setVersionToLS(version){
        localStorage.setItem('last-version-iptimizer', version);
    }
}
export default versionHandler;