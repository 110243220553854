class Validation {
    static isEmpty(value) {
        return value.trim() === ''; //убирает прообелы в начале и в конце
    }
}
class ValidationString extends Validation{
    constructor(password) {
        super();
        this.password = String(password);
    }
    isValidLength(minLength = 0, maxLength = Infinity) { //min max допустимы
        return this.password.length >= minLength && this.password.length <= maxLength;
    }
    isOnlyAvailableSymbols(regExp) {
        return regExp.test(this.password);
    }
    static getValidStroke(string, validArray, defaultString = validArray[0]){
        return validArray.includes(string) ? string : defaultString;
    }
    static emailValidation(email){
        const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return re.test(String(email).toLowerCase());
    }
}
class ValidationObject {
    static getObjectByValidKeys(keys, obj){
        const validObj = keys.reduce((acc, key) => {
            if (obj[key] !== undefined) {
                acc[key] = obj[key];
            }
            return acc;
        }, {});
        return validObj;
    }
    static getStringByEntriesFromObj(obj, separator = ','){
        return Object.entries(obj)
            .map(([ , value]) => `${value}`)
            .join(separator);
    }
    static getStringByValidKeys(keys, obj, separator = '/'){
        const str = keys.reduce((acc, key, index, array) => {
            if (obj[key] !== undefined) {
                acc += String(obj[key]);
                if(index !== array.length-1){
                    acc+=separator;
                }
            }
            return acc;
        }, '');
        return str;
    }
}
export {ValidationString, ValidationObject};