<template>
    <div class="input-file-input">
        <div class="input-file__label block-label">
            <name-input 
                :helpMessage="changeObj.helpMessage"
                :textToCopy="getLinkToCopyPath"
                :isRequiredToFilling="changeObj.isRequiredToFilling"
                :label="obj.label"
                :isColoredText="changeObj.isColoring">
            </name-input>
        </div>
        <div class="input-file__field block-field">
            <simple-files 
                :obj="changeObj"
                :state="{ persentToUploading: persentsToUpload }"
                @uploadFile="handleUploadFile"
                @deleteFile="handleDeleteFIleFromPath"
                @eventHandler="logEvents">
            </simple-files>
        </div>
    </div>
</template>

<script>
import SimpleFiles from '@/components/UI/typesInputs/SimpleFiles.vue';
import NameInput from '@/components/UI/typesInputs/NameInput.vue';

import { mapActions } from 'vuex';
import { ref } from 'vue';

import apiConfig from '@/config/api.config.js';
import $api from "@/http/index.js";
import ObjectService from '@/services/ObjectService';

import inputsHandler from '@/myLib/inputsHandler.js';

export default {
    name: "FilesInput",
    components: {
        NameInput,
        SimpleFiles
    },
    props: {
        obj: {
            label: String,
            items: String,
        },
        index: Array
    },
    setup(props){
        const changeObj = ref(props.obj);
        const apiFiles = ref(apiConfig.API_GATEWAY_FILES);
        const persentsToUpload = ref(0);
        return {
            changeObj, persentsToUpload, apiFiles
        }
    },
    watch: {
        'changeObj.isColoring'(newValue){
            if(newValue){
                this.handleColoring();
            }
        }
    },
    computed: {
        getLinkToCopyPath(){
            return inputsHandler.getLinkToCopyPath(this.$route, this.changeObj);
        }
    },
    methods: {
        ...mapActions({
            setError: 'error/setError',
            setMessageError: 'error/setMessageError',
            setStatusMenuState: 'systemInfo/setStatusMenuState',
        }),
        logEvents(obj){
            console.log(obj);
        },
        handleUploadFile(file){
            let formData = new FormData();
            formData.append('file', file);
            const id = this.$route.params.id;
            const url = `${this.apiFiles}/Object/PostFile/${id}/${this.obj.path}`;
            const options = {
                onUploadProgress: progressEvent => {
                    this.persentsToUpload = parseInt(progressEvent.progress * 100);
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            $api.post(
                url, formData, options
            ).then(response => {
                this.changeObj.items = JSON.parse(JSON.stringify(response.data?.items));
                console.log(response);
                this.setStatusMenuState(true);
                return response;
            }).catch((error)=>{
                console.error(
                `[${Date.now()}] [Critical] [TextInput:handleSavingChanges] [${JSON.stringify(error)}]
                `);
                this.setMessageError(error);
            }).finally(()=>{
                this.persentsToUpload = 0;
            });
        },
        handleDeleteFIleFromPath(item){
            const objToSend = {
                id: this.$route.params.id,
                path: this.obj.path,
                technicalFileName: item.TechnicalFileName,
                userFileName: item.UserFileName,
            }
            return ObjectService.DeleteFile(objToSend)
            .then(response => {
                this.changeObj.items = JSON.parse(JSON.stringify(response.data?.items));
                this.setStatusMenuState(true);
                return response;
            }).catch((error)=>{
                console.error(
                `[${Date.now()}] [Critical] [TextInput:handleSavingChanges] [${JSON.stringify(error)}]
                `);
                this.setMessageError(error);
            });
        },
        handleColoring(){
            inputsHandler.handleColoring(this.$el, this.$route, this.changeObj)
        }
    },
    mounted() {
        if(this.changeObj.isColoring){
            this.handleColoring();
        }
    },
}
</script>

<style scoped>
.input-file-input{
    width: 100%;
    height: max-content;
    display: flex;
    padding: 0px 20px 0px 10px;
    border-bottom: 1px solid var(--color-state-grey-100);
}
.block-field{
    flex: 1;
    min-width: 170px;
}
.block-label{
    flex: 1;
    max-width: 316px;
    min-width: 20px;
}
</style>