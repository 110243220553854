import $api from "@/http/index.js";
import apiConfig from '@/config/api.config.js';

export const sandboxModule = {
    state: () => ({
        api: apiConfig.API_GATEWAY,
        attributes: [ ],
        activeattributes: [ ],
        typeSandbox: '',
        activeElement:{
            listId: 0,
            itemId: 0,
        },
        isLoading: false,//загрузка не идет
        isChanged: false,
        typesPropety:[
            {
              "name": "propertyGroup",
              "realName": "Группа свойств"
            },
            {
              "name": "input",
              "realName": "Однострочное поле ввода"
            },
            {
              "name": "textarea",
              "realName": "Многострочное поле ввода"
            },
            {
              "name": "date",
              "realName": "Дата"
            },
            {
              "name": "file",
              "realName": "Файл"
            },
            {
              "name": "enum",
              "realName": "Выпадающий список"
            },
            {
              "name": "code",
              "realName": "LaTex-код"
            },
            {
              "name": "group",
              "realName": "Группа"
            },
            {
                "name": "collection",
                "realName": "Коллекция"
            }
        ], 
        mapTypeobjSettingsobj:{
            Input: {
                active: true,
                sorting: false,
                searching: false,
            },
            TextArea: {
                active: true,
                searching: false,
            },
            Date: {
                active: true,
                filtering: false,
                sorting: false,
            },
            File: {
                active: true,
                searching: false,
            },
            Enum: {
                active: true,
                filtering: false,
                listValues: '',
            },
            Code: {
                active: true,
            },
            Group: {
                active: true,
                collapsing: false,
            },
            Collection: {
                active: true,
            },
            PropertyGroup: {
                active: true,
            },
            All:{
                active: true,
                filtering: false,
                sorting: false,
                searching: false,
                collapsing: false,
                listValues: '',
            }
        },
        error: {
            isError: false,
            status: 0,
        },
    }),
    getters: {
        filteredListsProperties(state) {
            const res = [];
            let obj = state.attributes;
            try{
                for (let i = 0; i < state.activeattributes.length; ++i) {
                    res.push(obj);
                    
                    if (i === 0 || obj && obj[state.activeattributes[i]] && obj[state.activeattributes[i]]?.type === 'Group'
                        || obj[state.activeattributes[i]].type === 'Collection'
                    ) {
                        if(obj[state.activeattributes[i]].type === 'Collection'){
                            obj = obj[state.activeattributes[i]].itemType.attributes
                        }
                        else obj = obj[state.activeattributes[i]].attributes;
                        if (i === state.activeattributes.length - 1) {
                            res.push(obj || []);
                        }
                    }
                }
            } catch{
                return [];
            }
            
            return res;
        },
        getActiveattributes(state) {
            return state.activeattributes;
        },
        getActiveElement(state){
            return state.activeElement;
        },
        getTypes(state){//может пригодится для отображения имени
            return state.typesPropety;
        },
        getRealNameFromType(state){//отображать реальное имя вместо технического
            let name = state.activeElement.value.type;
            let iter = state.typesPropety;
            for(let i in iter){
                if(iter[i].name == name) return iter[i].realName;
            }
            return '';
        },
        getObjSettingsFromType: (state)=>(type)=>{//берем тип активного элемента и возвращем объект данного типа
            const obj = state.mapTypeobjSettingsobj[type];
            return obj;
        },
        getTitleFromListId: (state, getters) => (listId) => {
            if(listId === 0) return 'Вкладки';
            if(
                listId > 0 
                && (getters.filteredListsProperties[listId-1][state.activeattributes[listId-1]]
                    .type === 'Collection'
                )
            ) return 'Модель свойства';
            return 'Свойства';
        },
        findListIdItemIdFromName: (state) => (name)=>{
            if(!name){
                return [];
            }
            let arrToFind = name.split('.');
            let obj = state.attributes;
            const resItemId = [];
            let tempItemId = null;
            for (let i = 0; i < arrToFind.length; ++i) {
                tempItemId = obj.findIndex(
                    it=>{
                        return it.name == arrToFind[i];
                    }
                )
                if(tempItemId === -1) {
                    return [];
                }
                resItemId.push(tempItemId);
                if (i === 0 || obj[tempItemId]?.type === 'Group'
                    || obj[tempItemId].type === 'Collection'
                ) {
                    if(obj[tempItemId].type === 'Collection'){
                        obj = obj[tempItemId].itemType.attributes
                    }
                    else obj = obj[tempItemId].attributes;
                }
            }
            return [...resItemId];
        },
        findFirstElem: (state) => {
            let obj = state.attributes;
            let tempItemId = obj.findIndex(el => {
              return el.path === obj.toSorted((a, b) => a.order - b.order)[0].path;
            });
            return [tempItemId];
          }
    },
    mutations: {
        setattributes(state, attributes) {
            state.attributes = attributes;
        },
        setLoading(state, isLoading) {
            state.isLoading = isLoading;
        },
        setTypesPropety(state, typesPropety) {
            state.typesPropety = typesPropety;
        },
        setTypeSandbox(state, typeSandbox){
            state.typeSandbox = typeSandbox;
        },
        setActiveattributes(state, activeattributes) {
            state.activeattributes = activeattributes;
        },
        setActiveElement(state, obj){
            state.activeElement = obj;
        },
        setError(state, errorObj) {
            for(let i in errorObj) {
                state.error[i] = errorObj[i];
            }
        },
        setIsChanged(state, isChanged) {
            state.isChanged = isChanged;
        }
    },
    actions: {
        setActiveElementFromTechnicalName({getters, dispatch}, name){
            let activeElem = getters.findListIdItemIdFromName(name);//rename
            if(!activeElem || !name || !activeElem.length) {
                activeElem = getters.findFirstElem;
            } 
            for(let i = 0; i < activeElem.length; ++i){
                const obj = {
                    listId: i,
                    itemId: activeElem[i]
                }
                dispatch('setActiveElementToArray', obj);
            }
        },
        setIsChanged({commit}, isChanged){
            commit('setIsChanged', isChanged);
        },
        setActiveattributes({commit}, obj){
            commit('setActiveattributes', obj);
        },
        setActiveElement({commit}, obj){
            commit('setActiveElement', obj);
        },
        fetchPostSandbox({ state, commit }, objAttribute) {
            console.info(`[${Date.now()}] [LOG-FETCH-Post] [vuex:sandbox:fetchPostSandbox] 
                [объект на отправку после добавления атрибута в песочницу начало -> \n
                ${JSON.stringify(objAttribute)}\n\n <- конец]`
            );
            // commit('setLoading', true);//идет загрузка
            const url = `${state.api}/Sandbox/AddProperty?TypeObject=${state.typeSandbox}`;
            return $api.post(url, {}, {
                params:{
                    Path: objAttribute.path,
                    Name: objAttribute.label,
                    Type: objAttribute.type,
                    order: objAttribute.order,
                    typeConnection: objAttribute.typeConnection,
                    pathConnection: objAttribute.pathConnection
                }
            })
            .then(response => {
                commit('setattributes', response.data?.attributes);
                console.info(`[${Date.now()}] [LOG-FETCH-Post] [vuex:sandbox:fetchPostSandbox]
                    [объект ответа после добавления атрибута в песочницу начало -> \n
                    ${JSON.stringify(response)}\n\n <- конец url->${url}]`
                );
                return response;
            }).catch(e => {
                console.error(`[${Date.now()}] [Critical] [vuex:sandbox:fetchPostSandbox] 
                    [ошибка при добавлении атрибута песочницы начало -> \n${JSON.stringify(e)}\n\n <- конец]`);
                throw e;
            }).finally(() => {
                commit('setLoading', false);
            })
        },
        fetchGetSandbox({ state, commit, dispatch}, typeSandbox) {
            commit('setLoading', true);//идет загрузка
            commit('setTypeSandbox', typeSandbox)
            return $api.get(
                `${state.api}/Sandbox/GetSandbox?TypeObject=${typeSandbox}`,
            )
            .then(response => {
                commit('setattributes', response.data?.attributes);
                commit('setActiveattributes', []);
                // if(response.data?.attributes?.length > 0){
                //     commit('setActiveattributes', [0]);
                //     commit('setActiveElement', {
                //         listId: 0,
                //         itemId: 0,
                //     })
                // } else {
                //     commit('setActiveattributes', []);
                // }
                console.info(`[${Date.now()}] [LOG-FETCH-Get] [vuex:sandbox:fetchGetSandbox]
                    [объект ответа после получения песочницы начало -> \n
                    ${JSON.stringify(response)}\n\n <- конец]`
                );
                return response;
            }).catch(e => {
                dispatch('setError', e);
                console.error(`[${Date.now()}] [Critical] [vuex:sandbox:fetchGetSandbox] 
                    [ошибка при получении песочницы начало -> \n${JSON.stringify(e)}\n\n <- конец]`);
                throw e;
            }).finally(() => {
                commit('setLoading', false);
            })
        },
        fetchSetChangesToSandbox({state}, obj) {
            console.info(`[${Date.now()}] [LOG-FETCH-Put] [vuex:sandbox:fetchSetChangesToSandbox] 
                [объект на отравку изменений атрибута песочницы начало -> \n
                ${JSON.stringify(obj)}\n\n <- конец]`
            );
            const url = `${state.api}/Sandbox/ChangeProperty?TypeObject=${state.typeSandbox}`;
            return $api.put(url, obj, {
                params:{
                    path: obj.path
                }
            })
            .then(response => {
                console.info(`[${Date.now()}] [LOG-FETCH-Put] [vuex:sandbox:fetchSetChangesToSandbox]
                    [объект ответа после изменений атрибута песочницы начало -> \n
                    ${JSON.stringify(response)}\n\n <- конец]`
                );
                return response;
            }).catch(e => {
                console.error(
                    `[${Date.now()}] [Critical] [SandboxSettings:vuex:sandbox:fetchSetChangesToSandbox]\n
                    [начало -> \n${JSON.stringify(e)}\n\n <- конец]`
                );
                throw e;
            });
        },
        setActiveElementToArray({ commit, getters }, obj) { //для отображения стилей нажатых элементов
            let tempActiveattributes = getters.getActiveattributes;
            tempActiveattributes[obj.listId] = obj.itemId;
            commit('setActiveattributes', tempActiveattributes.slice(0, obj.listId + 1));
            commit('setActiveElement', obj);
        },
        getname({state}, realName){
            let iter = state.typesPropety;
            for(let i in iter){
                if(iter[i].realName == realName) return iter[i].name;
            }
            return '';
        },
        setError({commit}, e){
            for (let i in e) {
                commit('setError', {
                    [i]: e[i],
                });
            }
            commit('setError', {
                isError: true, 
                status: e.status ? e.status : -1,
            });
        }
    },
    namespaced: true
}