
import store from '@/store';
import { IInput } from '@/myLib/inputs/IInput.js';
class EnumHandler extends IInput{
    /**
    * @param guid id карточки
    * @param path путь свойства
    */  
    constructor(guid, path){
        super(guid, path);
    }
    /**
    * @param typeEntity тип сущности
    */
    async isNeedToRefreshObjectCardAfterChange(typeEntity){
        const path = this.getPath();
        const pathNeedRefresh = await store.dispatch('roleAndAuth/getStatusPathByTypeEntity', typeEntity)
        if(path !== pathNeedRefresh) return false;
        return true;
    }
}
export { EnumHandler }