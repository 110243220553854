<template>
    <div class="icon-button" ref="iconBut" @click="aga"
        :class="{
            [mapSizeToStyles[styles.size].class]: true,
            [mapTypeToStyles[styles.type].class]: true,
            [mapStateToClass[styles.state]?.area]:true,
        }">
        <spin-loader class="button_loading" v-if="isLoading"
            :sizeSpinner="mapSizeToLoaderSize[styles.size]"
            :colorSpinner="'var(--color-svg-path-stroke)'">
        </spin-loader>
        <slot 
            :width="mapSizeToStyles[styles.size].svg.width" 
            :height="mapSizeToStyles[styles.size].svg.height" 
            :fill="'var(--color-svg-path-stroke)'"
            :svg="mapStateToClass[styles.state]?.svg"
            :class="{'invisible': isLoading}">
        </slot>
    </div>
</template>

<script>
import SpinLoader from '@/components/UI/loaders/SpinLoader.vue';
import { ref } from 'vue';
export default {
    name: "IconButton",
    components: {
        SpinLoader
    },
    props: {
        styles: {
            type: {
                type: String
            }, //backgrond-color color
            size: String, //padding gap size font-size
            state: String, //backgrond-color color
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        let iconBut = ref(null);
        const type = ref(props.styles.type);
        const isArea = !(type.value === 'Link Color' || type.value === 'Link Grey');
        const mapTypeToStyles = {
            "Primary": {
                iconColor: "red",
                class: "primary",
            },
            "Primary Light": {
                iconColor: "red",
                class: "primary",
            },
            "Secondary Grey": {
                iconColor: "red",
                class: "secondary-grey",
            },
            "Dark Grey": {
                iconColor: props.styles.state !== 'disabled' ? "var(--color-state-grey-600)" : "var(--color-state-grey-300)",
                class: "dark-grey"
            },
            "Tertiary": {
                iconColor: props.styles.state !== 'disabled' ? "var(--color-state-grey-600)" : "var(--color-state-grey-200)",
                class: "tertiary"
            },
            "Link Grey": {
                iconColor: "var(--color-state-grey-600)",
                class: "delete-padding",
            },
        };
        const mapSizeToStyles = { // классы шрифтов + padding + size
            "tiny-fix": {
                svg:{
                    width: "16px",
                    height: "16px",
                },
                class: "size-tiny-fix"
            },
            
            "tiny": {
                svg:{
                    width: "16px",
                    height: "16px",
                },
                class: "size-tiny"
            },
            "xsm": {
                svg:{
                    width: "14px",
                    height: "14px",
                },
                class: isArea ? "size-xsm" : "size-xsm-no-area"
            },
            "sm": {
                svg:{
                    width: "18px",
                    height: "18px",
                },
                class: isArea ? "size-sm" : "size-sm-no-area"
            },
            "md": {
                svg:{
                    width: "18px",
                    height: "18px",
                },
                class: "size-md"
            },
            "lg": {
                svg:{
                    width: "20px",
                    height: "20px",
                },
                class: "size-lg"
            },
            "xl": {
                svg:{
                    width: "24px",
                    height: "24px"
                },
                class: "size-xl"
            },
        };
        const mapStateToClass = {
            "disabled": {
                svg: {
                    area: 'disabled_svg',
                    fill: '',
                    stroke: 'var(--color-state-grey-200)'
                },
                area: 'disabled_area',
            }
        };
        const mapSizeToLoaderSize = {
            "xsm": "8px",
            "sm": "10px",
            "md": "12px",
            "lg": "15px",
            "xl": "16px",
        };
        return {
            mapTypeToStyles, mapSizeToStyles, mapStateToClass,
            iconBut, mapSizeToLoaderSize
        }
    },
    methods: {
        aga(){
            console.log(this.$refs.iconBut);
        }
    },
}
</script>

<style scoped>
/* .invisible > *:not(.button_loading){
    visibility: hidden;
} */
.invisible > *{
    visibility: hidden;
}
.button_loading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.icon-button{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    --color-svg-path-stroke: var(--color-base-100);
}
/* secondary-grey */
.secondary-grey{
    cursor: pointer;
    --color-svg-path-stroke: var(--color-state-grey-600);
    background-color: var(--color-base-00);
    border: 1px solid var(--color-state-grey-200);
}
.secondary-grey:hover{
    --color-svg-path-stroke: var(--color-state-grey-600);
    background-color: var(--color-state-grey-200);
    box-shadow: 0px 1px 2px 0px rgba(45, 54, 67, 0.05);
}
.secondary-grey:focus{
    --color-svg-path-stroke: var(--color-state-grey-600);
    background-color: var(--color-state-grey-25);
    box-shadow: 0px 0px 0px 3px rgba(240, 243, 249, 1);
    box-shadow: 0px 1px 2px 0px rgba(240, 243, 249, 1);
}
.secondary-grey:active{
    --color-svg-path-stroke: var(--color-state-grey-600);
    background-color: var(--color-state-grey-25);
}
.secondary-grey:disabled{
    --color-svg-path-stroke: var(--color-state-grey-200);
    background-color: var(--color-state-grey-25);
}
/* tertiary */
.tertiary{
    --color-svg-path-stroke: var(--color-state-grey-600);
    background-color: var(--color-base-00);
}
.tertiary:hover{
    --color-svg-path-stroke: var(--color-primary-blue-500);
    background-color: var(--color-state-grey-25);
    box-shadow: 0px 1px 2px 0px rgba(45, 54, 67, 0.05);
}
.tertiary:focus{
    --color-svg-path-stroke: var(--color-primary-blue-500);
    background-color: var(--color-base-00); 
}
.tertiary:active{
    --color-svg-path-stroke: var(--color-primary-blue-500);
    background-color: var(--color-state-grey-25);
}
.tertiary:disabled{
    --color-svg-path-stroke: var(--color-state-grey-200);
    background-color: var(--color-base-00);
}
/* dark-grey */
.dark-grey {
    background-color: var(--color-state-grey-200); /* 200 должно быть иначе не видно*/
}
.dark-grey:hover{
    background-color: var(--color-state-grey-300);
    box-shadow: 0px 1px 2px 0px rgba(45, 54, 67, 0.05);
}
.dark-grey:focus{
    background-color: var(--color-state-grey-200); 
    box-shadow: 0px 0px 0px 3px rgba(240, 243, 249, 1);
    box-shadow: 0px 1px 2px 0px rgba(240, 243, 249, 1);
}
.dark-grey:active{
    background-color: var(--color-state-grey-300);
    box-shadow: 0px 1px 2px 0px rgba(45, 54, 67, 0.05);
}
.dark-grey:disabled{
    background-color: var(--color-state-grey-100);
}
/* size-block */
.size-tiny {
    width: 32px;
    height: 32px;
    padding: 8px;
    border-radius: 8px;
}
.size-tiny-fix {
    width: 22px;
    height: 22px;
    padding: 4px;
    border-radius: 8px;
}
.size-xsm {
    width: 38px;
    height: 38px;
    padding: 10px;
    border-radius: 8px;
}
.size-xsm-no-area{
    width: 18px;
    height: 18px;
}
.size-sm {
    width: 42px;
    height: 42px;
    padding: 12px;
    border-radius: 10px;
}
.size-md {
    width: 50px;
    height: 50px;
    padding: 16px;
    border-radius: 10px;
}
.size-lg {
    width: 56px;
    height: 56px;
    padding: 18px;
    border-radius: 10px;
}
.size-xl {
    width: 60px;
    height: 60px;
    padding: 18px;
    border-radius: 10px;
}
.delete-padding{
    padding: 0px;
}
.disabled_area{
    background-color: var(--color-state-grey-100);
    cursor: not-allowed;
}
.disabled_area:hover, .disabled_area:active, .disabled_area:focus{
    background-color: var(--color-state-grey-100);
}

</style>