<template>
    <div class="input-date-input">
        <div class="input-date-input__label block-label">
            <name-input 
                :helpMessage="changeObj.helpMessage"
                :textToCopy="getLinkToCopyPath"
                :isRequiredToFilling="changeObj.isRequiredToFilling"
                :label="changeObj.label"
                :isColoredText="changeObj.isColoring">
            </name-input>
        </div>
        <div class="input-date-input__field block-field">
            <simple-date 
                :obj="changeObj"
                @saveNewValue="handleSavingChanges"
                @eventHandler="logEvents">
            </simple-date>
        </div>
    </div>
</template>

<script>
import SimpleDate from '@/components/UI/typesInputs/SimpleDate.vue';
import NameInput from '@/components/UI/typesInputs/NameInput.vue';

import { mapActions } from 'vuex';
import { ref } from 'vue';

import inputsHandler from '@/myLib/inputsHandler.js';

export default {
    name: "DateInput",
    components: {
        SimpleDate,
        NameInput
    },
    props: {
        obj: {
            label: String,
            items: String,
        },
        index: Array
    },
    setup(props){
        const changeObj = ref(props.obj);
        return {
            changeObj
        }
    },
    watch: {
        'changeObj.isColoring'(newValue){
            if(newValue){
                this.handleColoring();
            }
        }
    },
    computed: {
        getLinkToCopyPath(){
            return inputsHandler.getLinkToCopyPath(this.$route, this.changeObj);
        }
    },
    methods: {
        ...mapActions({
            setMessageError: 'error/setMessageError',
            setError: 'error/setError',
            setStatusMenuState: 'systemInfo/setStatusMenuState',
        }),
        logEvents(obj){
            console.log(obj);
        },
        handleSavingChanges(newValue){
            const value = newValue;
            const id = this.$route.params.id;
            const path = this.obj.path;
            const objToSave = {
                value: value,
                systemGuid: id,
                path: path,
            }
            inputsHandler.fetchSaveChanges(objToSave)
            .catch((error)=>{
                console.error(
                `[${Date.now()}] [Critical] [TextArea:handleSavingChanges] [${JSON.stringify(error)}]
                `);
                this.setMessageError(error);
            })
            .then(()=>{
                this.setStatusMenuState(true);
                this.changeObj.items = newValue;
            });
        },
        handleColoring(){
            inputsHandler.handleColoring(this.$el, this.$route, this.changeObj)
        }
    },
    mounted() {
        if(this.changeObj.isColoring){
            this.handleColoring();
        }
    },
}
</script>

<style scoped>
.input-date-input{
    width: 100%;
    height: max-content;
    display: flex;
    padding: 0px 20px 0px 10px;
    border-bottom: 1px solid var(--color-state-grey-100);
}
.block-field{
    flex: 1;
    min-width: 20px;
}
.block-label{
    flex: 1;
    max-width: 316px;
    min-width: 20px;
}
</style>