import apiConfig from '@/config/api.config.js';
import router from '@/router/router';
export const roleAndAuthModule = {
    state: () => ({
        api: apiConfig.API_GATEWAY,
        userRoleConfig: { },
        mapTypeCardToTypeComponent: {
            "big" : {
                componentCards: "PageCardsObjects",
            },
        }
    }),
    getters: {
        getUserRoleConfig(state) {
            return state.userRoleConfig;
        },
        getDynamicTabs(state){
            const userRoleConfig = state.userRoleConfig
            if(!userRoleConfig || !userRoleConfig.role || !userRoleConfig.role.Objects || !userRoleConfig.role.Objects.length){
                return [];
            }
            let tabs = userRoleConfig.role.Objects
            .map((it)=>{
                return {
                    name: it.name,
                    label: it.label,
                    path: `/${it.name}`,
                    pathToNavigate: `/${it.name}`,
                    typeComponent: it.typeCard,
                    isOpenCardEditable: it.isEditable,
                    pathIcon: `@/assets/icons-navbar/tab-${it.name}.svg`,
                }
            })
            return [...tabs];
        },
        isValidRouteSandbox: (state, getters) => (route) => {
            return getters.getDynamicTabs.find((it)=>{
                return it.name === route
            }) !== -1
        },
        getStaticTabs(state, getters){
            const userRoleConfig = state.userRoleConfig
            if(!userRoleConfig || !userRoleConfig.role){
                return [];
            }
            let res = [];
            if(userRoleConfig.role.canSeeSandbox){
                res.push({
                    name: 'sandbox',
                    label: 'Песочница',
                    pathToNavigate: '/sandbox',
                    path: '/sandbox/:type?/:name?',
                    component: async () => await import('@/pages/PageSandbox.vue'),
                    beforeEnter: (to, from, next) => {
                        if (
                            !getters.isValidRouteSandbox(to.params.type)
                        ) {
                          to.params.type = getters.getDynamicTabs[0].name;
                        } 
                        next();
                    }
                })
            }
            if(userRoleConfig.role.canUseTestFunctions){
                res.push({
                    name: 'factory',
                    label: 'Тестовые функции',
                    pathToNavigate: '/factory',
                    path: '/factory',
                    component: async () => await import('@/pages/PageFactory.vue'),
                })
            }
            if(userRoleConfig.role.canSeeGlobalReports){
                res.push({
                    name: 'PageGeneralReports',
                    label: 'Общие отчеты',
                    pathToNavigate: '/reports/9080b595-4b6c-42c7-a1d8-aa5c5afe1492',
                    path: '/reports/9080b595-4b6c-42c7-a1d8-aa5c5afe1492',
                    component: async () => await import('@/pages/PageGeneralReports.vue'),
                    children: [
                        {
                            path: 'latex-editor/:path/:isColoredRequiredFields',
                            component: async () => await import(
                               '@/components/latex-editor/LatexEditor.vue'
                            ),
                            props: true
                        }
                    ]
                })
            }
            if(userRoleConfig.role.canViewRoles || userRoleConfig.role.canViewUsers){
                const validRoutes = [];
                if(userRoleConfig.role.canViewRoles){
                    validRoutes.push('roles');
                }
                if(userRoleConfig.role.canViewUsers){
                    validRoutes.push('users');
                }
                res.push({
                    name: 'administration',
                    label: 'Администрирование',
                    pathToNavigate: '/administration',
                    path: '/administration/:type?',
                    component: () => import('@/pages/PageAdmin.vue'),
                    beforeEnter: (to, from, next) => {
                        if (
                            !validRoutes.includes(to.params.type)
                        ) {
                          to.params.type = validRoutes[0];
                        } 
                        next();
                    }
                })
            }
            return [...res];
        },
        getPathComponentCardsFromTypeComponent: (state) => (typeComponent) => {
            return `${state.mapTypeCardToTypeComponent[typeComponent]?.componentCards}.vue`;
        },
        getPathComponentOpenCard(){
            return `PageInfo.vue`;
        },
        getCreatingParamsFromType: (state) => (type) => {
            return state.userRoleConfig.role?.Objects
            ?.find((it)=>(it.name === type))
            ?.CreationParameters || [];
        },
        getIsOpenCardEditable: (state) => (type) => {
            return state.userRoleConfig.role?.Objects
            ?.find((it)=>(it.name === type))
            ?.isEditable || false;
        },
        getIsGlobalReportsEditable(state){
            return state.userRoleConfig.role?.canEnableGlobalReports || false;
        },
        getRoutesAdminPage(state){
            const userRoleConfig = state.userRoleConfig;
            const validRoutes = [];
            if(userRoleConfig.role.canViewRoles){
               validRoutes.push('roles');
            }
            if(userRoleConfig.role.canViewUsers){
                validRoutes.push('users');
            }
            return validRoutes;
        }
    },
    mutations: {
        setUserRoleConfig(state, userRoleConfig) {
            state.userRoleConfig = userRoleConfig;
        },
    },
    actions: {
        getStatusPathByTypeEntity({ getters }, typeEntity){
            const config = getters.getUserRoleConfig;
            if(!config?.role?.Objects?.length) return null;

            const entityArray = config.role.Objects;
            return entityArray.find(it=>{
                return it.name === typeEntity;
            })?.config?.StatusPath || '';
        },
        setUserRoleConfig({ commit, dispatch }, userRoleConfig) {
            commit('setUserRoleConfig', JSON.parse(JSON.stringify(userRoleConfig)));
            dispatch('addDynamicRoutes');
            dispatch('addStaticRoutes');
            router.addRoute({ 
                path: '/:pathMatch(.*)*',
                redirect: '/error'
            })
        },
        addDynamicRoutes({ getters }){
            const routes = getters.getDynamicTabs
            .flatMap((it)=>{ return [
                {
                    path: it.path,
                    name: it.name,
                    component: async () => await import(
                        `@/pages/${getters.getPathComponentCardsFromTypeComponent(it.typeComponent)}` //'@/pages/PageCardsBig.vue'
                    ),
                    props: { type: it.name }
                },
                {
                    path: it.path + '/:id/:activeAttributeSystem?/:activeField?',
                    name: it.name + 'Info',
                    component: async () => await import(
                        `@/pages/${getters.getPathComponentOpenCard}`
                    ),
                    children: [
                        {
                            path: 'latex-editor/:path/:isColoredRequiredFields',
                            component: async () => await import(
                               '@/components/latex-editor/LatexEditor.vue'
                            ),
                            props: true
                        }
                    ]
                }
            ]})
            routes.forEach(el => {
                router.addRoute(el);
            });
            console.log('addDynamicRoutes', routes);
        },
        addStaticRoutes( { state, getters } ){
            const userRoleConfig = state.userRoleConfig
            if(!userRoleConfig || !userRoleConfig.role){
                return [];
            }
            let res = [];
            if(userRoleConfig.role.canSeeSandbox){
                res.push({
                    name: 'sandbox',
                    label: 'Песочница',
                    pathToNavigate: '/sandbox',
                    path: '/sandbox/:type?/:name?',
                    component: () => import('@/pages/PageSandbox.vue'),
                    beforeEnter: (to, from, next) => {
                        if (
                            !getters.isValidRouteSandbox(to.params.type)
                        ) {
                          to.params.type = getters.getDynamicTabs[0].name;
                        } 
                        next();
                    }
                })
            }
            if(userRoleConfig.role.canUseTestFunctions){
                res.push({
                    name: 'factory',
                    label: 'Тестовые функции',
                    pathToNavigate: '/factory',
                    path: '/factory',
                    component: () => import('@/pages/PageFactory.vue'),
                })
            }
            if(userRoleConfig.role.canSeeGlobalReports){
                res.push({
                    name: 'PageGeneralReports',
                    label: 'Общие отчеты',
                    pathToNavigate: '/reports/9080b595-4b6c-42c7-a1d8-aa5c5afe1492/',
                    path: '/reports/:id',
                    component: () => import('@/pages/PageGeneralReports.vue'),
                    children: [
                        {
                            path: 'latex-editor/:path/:isColoredRequiredFields',
                            component: async () => await import(
                               '@/components/latex-editor/LatexEditor.vue'
                            ),
                            props: true
                        }
                    ]
                })
            }
            if(userRoleConfig.role.canViewRoles || userRoleConfig.role.canViewUsers){
                const validRoutes = [];
                if(userRoleConfig.role.canViewRoles){
                    validRoutes.push('roles');
                }
                if(userRoleConfig.role.canViewUsers){
                    validRoutes.push('users');
                }
                res.push({
                    name: 'administration',
                    label: 'Администрирование',
                    pathToNavigate: '/administration',
                    path: '/administration/:type?',
                    component: () => import('@/pages/PageAdmin.vue'),
                    beforeEnter: (to, from, next) => {
                        if (
                            !validRoutes.includes(to.params.type)
                        ) {
                          to.params.type = getters.getDynamicTabs[0].name;
                        } 
                        next();
                    }
                })
            }
            res.forEach(el => {
                const temp = {...el}
                delete temp.pathToNavigate
                router.addRoute(temp);
            });
        },
        getAvailableRoutesFromRole( {getters} ){
            const callBack = (it) => {
                return {
                    name: it.name,
                    label: it.label,
                    pathToNavigate: it.pathToNavigate,
                }
            }
            return [...getters.getDynamicTabs.map(callBack),
            ...getters.getStaticTabs.map(callBack)]
        },
    },
    namespaced: true
}