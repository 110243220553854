import apiConfig from '@/config/api.config.js';
import GeneralReportsS from '@/services/GeneralReportsService';

export const reportsModule = {
    state: () => ({
        api: apiConfig.API_GATEWAY_REPORTS,
        id: '',
        statePage:{
            isReadOnly: true,
            isLoading: false,
            isError: false,
        },
        activeReports:{ },
        editingReports:{ },
        error:{ }
    }),
    getters: {
        getActiveReports: (state) => {
            return state.activeReports;
        },
        getEditingReports: (state) => {
            return state.editingReports;
        },
    },
    mutations: {
        setActiveReports(state, activeReports) {
            state.activeReports = activeReports;
        },
        setEditingReports(state, editingReports){
            state.editingReports = editingReports;
        },
        setStatePage(state, objStatePage){
            for(let i in objStatePage){
                state.statePage[i] = objStatePage[i];
            }
        },
        setError(state, objError){
            for(let i in objError){
                state.error[i] = objError[i];
            }
        },
        setId(state, id){
            state.id = id;
        }
    },
    actions: {
        fetchReports({ commit }){
            return GeneralReportsS.GetReportsObject()
            .then(response=>{
                if(response.status == 200){
                    commit('setId', response.data.id);
                    const reports = response.data?.tabs?.find(
                        (item)=>{
                            return item.name == 'GlobalReports';
                        }
                    )?.items.find(
                        (item)=>{
                            return item.name == 'ReportsCollection';
                        }
                    );
                    commit('setActiveReports', reports);
                }
                return response;
            })
        },
        setError({commit}, e){
            for (let i in e) {
                commit('setError', {
                    [i]: e[i],
                });
            }
            commit('setError', {
                isError: true, 
                status: e.status ? e.status : -1,
            });
        },
        setEditingReports({commit}, objEditingReports){
            commit('setEditingReports', JSON.parse(
                JSON.stringify(objEditingReports)
            ))
        },
        setStatePage({commit}, objStatePage){
            commit('setStatePage', objStatePage);
        },
    },
    namespaced: true
}