<template>
    <div class="pdocs">
        <header class="pdocs__filters">
            <search-input @addFilterValue="handleAddFilterValue" @removeFilterValue="handleRemoveFilterValue">
            </search-input>
        </header>
        <!-- <button @click="handleDownloadPDFReports">Скачать все пдф</button> -->
        <main class="pdocs__documents scroll-bar-grey-thin">
            <div v-if="this.isPageDocsLoading"
                class="loading">
            <get-loading :title="'Загрузка'"></get-loading>
            </div>
            <empty-content class="pdocs__documents__empty"
                v-else-if="!(this.filterDocs?.length)"
                :text="'Не нашлось документов'"
                >
            </empty-content>
            
            <card-document v-else
                :key="JSON.stringify(item)" v-for="item in filterDocs" :obj="item">
            </card-document>
            
        </main>
        
    </div>
</template>

<script>
import EmptyContent from '@/components/UI/info-components/EmptyContent.vue';
import CardDocument from '@/components/docs-template/CardDoc.vue';
import SearchInput from '@/components/UI/filters/SearchPanelPage.vue'

import apiConfig from '@/config/api.config.js';
import ObjectService from '@/services/ObjectService';
import fileHandler from '@/myLib/fileHandler.js';
import { ref } from 'vue';
export default {
    name: "PageDocsTemplates",
    components: {
        CardDocument,
        SearchInput,
        EmptyContent
    },
    setup() {
        const templates = ref([])
        let isLoading = ref(false);
        let searchFilterValue = ref('');
        let isPageDocsLoading = ref(false);
        const fileNames = ref([
            { name: `01. Отчет о проведении исследования инициатив по разработке программного продукта_форма`},
            { name: `02. Экономическое обоснование_форма`,
                pagesWidth: [650, 650, 1000, 650]
            },
            { name: `03. Приказ (распоряжение) о начале разработки_форма`},
            { name: `04. Техническое задание_форма`},
            { name: `05. Приказ (распоряжение) об изменении приказа (распоряжения) о начале разработки_форма`},
            { name: `06. Изменения к техническому заданию_форма`},
            { name: `07. Частное техническое задание_форма`},
            { 
                name: `08. Служебная записка о составе команды_форма`,
                pagesWidth: [1000]
            },
            { name: `09. Служебная записка затраты_форма`},
            { name: `10. Акт о завершении разработки_форма`},
            { name: `11. Спецификация_форма`},
            { name: `12. Программа и методика испытаний_форма`},
            { name: `13. Протокол приемочных испытаний_форма`},
            { name: `14. Акт передачи служебного произведения работодателю_форма`},
            { name: `15. Акт ввода в эксплуатацию_форма`},
            { name: `16. Заключение управления правового сопровождения ИТ`},
            { 
                name: `17. Карточка учета нематериальных активов`,
                pagesWidth: [1000, 1000]
            },
            { name: `18. Отчет о проведении исследования инициатив по разработке Релиза_форма`},
            {   
                name: `19. Экономическое обоснование на релиз_форма`,
                pagesWidth: [650, 650, 1000, 650]
            },
            { name: `20. Приказ (распоряжение) о начале разработки релиза_форма`},
            { name: `21. Техническое задание на релиз_форма`},
            { name: `22. Акт о завершении разработки релиза_форма`},
            { name: `23. Пояснительная записка на релиз_форма`},
            { name: `24. Протокол приемочных испытаний релиза_форма`},
            { name: `25. Заключение комиссии_форма`},
            { name: `26. Приказ о принятии НМА_форма`},
            { name: `27. Приказ о прекращении работ по созданию НМА_форма`},
            { name: `28. Приказ о списании капитальных вложений`},
            { name: `29. Приказ о прекращении использования НМА и перевод в состав долгосрочных активов_форма`},
            { name: `30. Приказ о прекращении использования НМА_форма`},
            { name: `31. Приказ списание НМА_форма`},
        ]);
        const api = apiConfig.API_GATEWAY_FILES
        return {
            isLoading, templates, searchFilterValue, isPageDocsLoading, fileNames, api
        }
    },
    methods: {
        async getObjBlobFromUrl(url){
            const res = await fetch(url)
            return await res.blob();
        },
        getFileName(url) {
            const urlName = new URL(url);
            return decodeURIComponent(
                urlName.pathname.split("/").pop().split("?")[0]
            );
        },
        downloadFileFromBlob(blob , fileName){
            const a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = fileName;
            a.click();
            URL.revokeObjectURL(a.href);
        },
        async handleDownloadPDFReports(){
            const id = '297a873f-ecb7-4314-87cc-1a13541fc6f5';
            const path = 'FEqwertg.GenerateTemplateDocs';
            for(let i = 0; i < this.templates.length; ++i){
                try {
                    const objToSendCode = {
                        id: id,
                        path: path,
                        data: this.templates[i].value
                    }
                    await ObjectService.PutCode(objToSendCode)
                    delete objToSendCode.data;
                    const linkFileResponse = await ObjectService.GetLinkCode(objToSendCode);
                    const blob = await this.getObjBlobFromUrl(linkFileResponse.data);
                    const fileName = this.templates[i].name + '.pdf';
                    await this.downloadFileFromBlob(blob, fileName);
                } catch (e) {
                    alert(e);
                }
            }
        },
        handleSavingChanges(newValue) {
            const objToSend = {
                id: this.$route.params.id,
                path: this.obj.path,
                data: newValue
            }
            return ObjectService.PutCode(objToSend)
            .catch((error) => {
                console.error(
                    `[${Date.now()}] [Critical] [Latex] [${JSON.stringify(error)}]
                `);
                this.setMessageError(error);
            })
            .then((response) => {
                this.setStatusMenuState(true);
                this.changeObj.items = newValue;
                this.changeObj.status = response.data.status;
                this.changeObj.error = response.data.error;
            })
        },

        handleAddFilterValue(newValueFilter) {
            this.searchFilterValue = newValueFilter;
        },
        handleRemoveFilterValue() {
            this.searchFilterValue = '';
        },
        async setDocsTemplates(){
            for(let i = 0; i < this.fileNames.length; ++i){
                try{
                    const nameFile = this.fileNames[i].name;
                    const pagesWidth = this.fileNames[i].pagesWidth;
                    const text = await fileHandler.getTextFromFilePath(`/resource/template-docs/latex/${nameFile.slice(0, 2)}.tex`);
                    const obj = {
                        urlFile: `/resource/template-docs/pdf/${nameFile}.pdf`,
                        name: nameFile,
                        value: text,
                        pagesWidth: pagesWidth || []
                    }
                    this.templates.push(obj);
                } catch(e){
                    console.error(`Error processing template ${this.fileNames[i]}: ${e}`);
                }  
            }
        },
        async setTemplates() {
            try {
                let text = null;
                text = await fileHandler.getTextFromFilePath(
                    '/resource/template-docs/tex/1.tex'
                );
                this.templates.push({
                    urlFile: '/resource/template-docs/pdf/1.pdf',
                    name: 'Служебное задание на разработку нового программного обеспечения.',
                    value: text
                });
                text = await fileHandler.getTextFromFilePath(
                    '/resource/template-docs/tex/2.tex'
                );
                this.templates.push({
                    urlFile: '/resource/template-docs/pdf/2.pdf',
                    name: 'Акт о завершении выполнения служебного задания.',
                    value: text,
                });
                text = await fileHandler.getTextFromFilePath(
                    '/resource/template-docs/tex/3.tex'
                );
                this.templates.push({
                    urlFile: '/resource/template-docs/pdf/3.pdf',
                    name: 'Служебная записка о составе команды, ролях, затратах рабочего времени на разработку продукта.',
                    value: text
                });
                text = await fileHandler.getTextFromFilePath(
                    '/resource/template-docs/tex/4.tex'
                );
                this.templates.push({
                    urlFile: '/resource/template-docs/pdf/4.pdf',
                    name: 'Справка о составе команды (таблица).',
                    value: text,
                    width: 1000
                });
                text = await fileHandler.getTextFromFilePath(
                    '/resource/template-docs/tex/5.tex'
                );
                this.templates.push({
                    urlFile: '/resource/template-docs/pdf/5.pdf',
                    name: 'Служебная записка о составе авторов программы для ЭВМ.',
                    value: text
                });
                text = await fileHandler.getTextFromFilePath(
                    '/resource/template-docs/tex/6.tex'
                );
                this.templates.push({
                    urlFile: '/resource/template-docs/pdf/6.pdf',
                    name: 'Спецификация программного обеспечения.',
                    value: text,
                });
                text = await fileHandler.getTextFromFilePath(
                    '/resource/template-docs/tex/7.tex'
                );
                this.templates.push({
                    urlFile: '/resource/template-docs/pdf/7.pdf',
                    name: 'Акт приема (оприходования) нематериальных активов и исключительных имущественных прав на программное обеспечение.',
                    value: text
                });
                text = await fileHandler.getTextFromFilePath(
                    '/resource/template-docs/tex/8.tex'
                );
                this.templates.push({
                    urlFile: '/resource/template-docs/pdf/8.pdf',
                    name: 'Акт приема-передачи прав на служебное произведение (ПО для ЭВМ) от работника к работодателю.',
                    value: text,
                });
                text = await fileHandler.getTextFromFilePath(
                    '/resource/template-docs/tex/9.tex'
                );
                this.templates.push({
                    urlFile: '/resource/template-docs/pdf/9.pdf',
                    name: 'Акт о вводе в эксплуатацию.',
                    value: text
                });
                text = await fileHandler.getTextFromFilePath(
                    '/resource/template-docs/tex/10.tex'
                );
                this.templates.push({
                    urlFile: '/resource/template-docs/pdf/10.pdf',
                    name: 'Реферат программы (ЭВМ).',
                    value: text,
                });
                text = await fileHandler.getTextFromFilePath(
                    '/resource/template-docs/tex/11.tex'
                );
                this.templates.push({
                    urlFile: '/resource/template-docs/pdf/11.pdf',
                    name: 'Согласие на обработку персональных данных.',
                    value: text,
                });
                text = await fileHandler.getTextFromFilePath(
                    '/resource/template-docs/tex/13.tex'
                );
                this.templates.push({
                    urlFile: '/resource/template-docs/pdf/13.pdf',
                    name: 'Дополнение к заявлению о государственной регистрации ПО.',
                    value: text,
                });
                text = await fileHandler.getTextFromFilePath(
                    '/resource/template-docs/tex/14.tex'
                );
                this.templates.push({
                    urlFile: '/resource/template-docs/pdf/14.pdf',
                    name: 'Согласие автора на указания сведений об авторе, указанных в заявлении.',
                    value: text,
                });
                text = await fileHandler.getTextFromFilePath(
                    '/resource/template-docs/tex/15.tex'
                );
                this.templates.push({
                    urlFile: '/resource/template-docs/pdf/15.pdf',
                    name: 'Опись на регистрацию ПО.',
                    value: text,
                });
                text = await fileHandler.getTextFromFilePath(
                    '/resource/template-docs/tex/16.tex'
                );
                this.templates.push({
                    urlFile: '/resource/template-docs/pdf/16.pdf',
                    name: 'Программа для ЭВМ (Титульный лист).',
                    value: text,
                });
                text = await fileHandler.getTextFromFilePath(
                    '/resource/template-docs/tex/17.tex'
                );
                this.templates.push({
                    urlFile: '/resource/template-docs/pdf/17.pdf',
                    name: 'Служебная записка о произведенных затратах в рамках разработки НМА за период.',
                    value: text,
                });
            } catch (e) {
                console.error(`[${Date.now()}] [Critical] [PageDocsTemplates:getTemplates] [
                    начало -> \n${e} <-\n\n конец]`);
            }
        }
    },
    computed:{
        filterDocs(){
            return this.templates.filter((item)=>{
                return item.name.toLowerCase().includes(this.searchFilterValue.toLowerCase())
            })
        }
    },
    async mounted() {
        this.isPageDocsLoading = true;
        await this.setDocsTemplates().then(()=>{
            this.isPageDocsLoading = false;
        });
    }
}
</script>

<style scoped>
.qwerty {
    border: 10px solid green !important;
}
.pdocs {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 24px 20px;
}
.pdocs__filters {
    position: relative;
    width: 100%;
}
.pdocs__documents {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 350px));
    grid-auto-rows: min-content;
    gap: 20px;
}
.loading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>