<template>
    <div class="name-input" @click="handleClick">   
        <div class="name-input__help-icon" v-if="helpMessage">
            <svg class="svg-details" width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect width="16" height="16" rx="8" fill="#AFBACA" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M9.03142 4.21338C9.03142 4.78373 8.56906 5.24609 7.99871 5.24609C7.42836 5.24609 6.96599 4.78373 6.96599 4.21338C6.96599 3.64303 7.42836 3.18066 7.99871 3.18066C8.56906 3.18066 9.03142 3.64303 9.03142 4.21338ZM7.31104 6.62305C6.9308 6.62305 6.62256 6.93129 6.62256 7.31152C6.62256 7.69176 6.9308 8 7.31104 8V12.1309C7.31104 12.5111 7.61928 12.8193 7.99951 12.8193H8.68799C9.06822 12.8193 9.37646 12.5111 9.37646 12.1309C9.37646 11.7506 9.06822 11.4424 8.68799 11.4424L8.68799 7.31152C8.68799 6.93129 8.37975 6.62305 7.99951 6.62305H7.31104Z"
                    fill="white"/>
            </svg>
            <tooltip-area class="tooltip-to-help-writing"
                :text="helpMessage"
                >
            </tooltip-area> 
        </div>     
        <div class="name-input__value font-paragraph-5-regular text-overflow-ellipsis"
            :class="{'text-background-colored': isColoredText}">
            {{ label }}
        </div>
        <div v-if="isRequiredToFilling"
            class="name-input__right-icon font-paragraph-5-regular">
*
            <tooltip-area class="tooltip-required-property"
                :text="'Обязательно к заполнению в текущем статусе'"
                :positionMarker="'left-side'">
            </tooltip-area>  
        </div>
        <div class="name-input__copy-element" @click="handleClick">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.4999 10.4995H13.4999V2.49951H5.49994V5.49951M2.49988 5.49958H10.4999V13.4996H2.49988V5.49958Z" stroke="#5E718D" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
    </div>
</template>

<script>
import TooltipArea from '@/components/UI/tooltips/TooltipArea.vue';

import StringHandler from '@/myLib/StringHandler';
export default {
    name: "NameInput",
    components:{
        TooltipArea,
    },
    props:{
        textToCopy: {
            type: String,
            default: ''
        },
        label: String,
        isRequiredToFilling:{
            type: Boolean,
            default: false
        },
        isColoredText:{
            type: Boolean,
            default: false
        },
        helpMessage: {
            type: String,
            default: ''
        }
    },
    methods: {
        handleClick(){
            StringHandler.copyStringToClipboard(this.textToCopy);
        }
    }
}
</script>

<style scoped>
.text-background-colored{
    background-color: var(--color-warning-yellow-50);
}
.name-input__copy-element{
    display: none;
}
.name-input:hover > .name-input__copy-element{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    overflow: visible;
}
.name-input{
    width: 100%;
    max-height: 40px;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
}
.name-input__right-icon{
    cursor:context-menu;
    user-select: none;
    color: var(--color-error-red-300);
    width: fit-content;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    position: relative;
}
.name-input__value{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-state-grey-600);
}
.tooltip-required-property{
    display: none;
    width: 200px;
}
.name-input__right-icon:hover .tooltip-required-property{
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(17px, -50%);
    z-index: 100;
}
.tooltip-to-help-writing{
    display: none;
}
.name-input__help-icon{
    display: flex;
    align-self: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}
.name-input__help-icon:hover > .tooltip-to-help-writing{
    display: block;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(14px, 0%);
    z-index: 100;
    width: 400px;
}
</style>