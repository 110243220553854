<template>
    <div v-if="obj.isEditable" v-on:focusout="handleFocusOut" class="template-date" tabindex="1">
        <div class="simple-date font-paragraph-5-regular"
            :class="obj.isRequiredToFilling && !obj.items ? 'requiredToFilling' : ''">
            <div class="simple-date__value" @click="handleSetEditMode($event)">
                <div class="simple-date__value__field">
                    <svg @click="openCalendar" class="simple-date__value__calendar-icon" width='16px' height='16px'
                        viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.125 5.875H14.875M11.75 0.875V3.375M4.25 0.875V3.375M1.75 2.125H14.25C14.5952 2.125 14.875 2.40482 14.875 2.75V15.25C14.875 15.5952 14.5952 15.875 14.25 15.875H1.75C1.40482 15.875 1.125 15.5952 1.125 15.25V2.75C1.125 2.40482 1.40482 2.125 1.75 2.125Z"
                            stroke="var(--color-state-grey-900)"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <input
                        :key="this.keyInput"
                        :class="value ? 'not-empty' : 'empty'"
                        type="date" class="input-date-but" :title="this.value" @mouseup="handleMouseUp"
                        v-model="value" placeholder="Введите значение" ref="dateField">
                </div>
                <div v-if="!stateInput.isInputInEditing" class="simple-date__value__button">
                    <icon-button class="right-icon-in-date"
                        :styles="{ type: 'Dark Grey', size: 'tiny', state: state.disabled ? 'disabled' : '' }">
                        <template v-slot="{ width, height }">
                            <svg :width=width :height="height" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.625 5.00015L15 9.37515M7.5 16.8751H3.75C3.58424 16.8751 3.42527 16.8093 3.30806 16.6921C3.19085 16.5749 3.125 16.4159 3.125 16.2501V12.759C3.125 12.677 3.14117 12.5957 3.17258 12.5199C3.20398 12.444 3.25002 12.3751 3.30806 12.3171L12.6831 2.94209C12.8003 2.82488 12.9592 2.75903 13.125 2.75903C13.2908 2.75903 13.4497 2.82488 13.5669 2.94209L17.0581 6.43321C17.1753 6.55042 17.2411 6.70939 17.2411 6.87515C17.2411 7.04091 17.1753 7.19988 17.0581 7.31709L7.5 16.8751Z"
                                    stroke="#5E718D" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </template>
                    </icon-button>
                </div>
            </div>
        </div>
        <div v-if="stateInput.isInputInEditing" class="simple-date-edit-buttons">
            <div class="simple-date-edit-buttons__buttons">
                <icon-button @click="handleSavingChanges"
                    :styles="{ type: 'Dark Grey', size: 'tiny', state: state.disabled ? 'disabled' : '' }">
                    <template v-slot="{ width, height }">
                        <svg :fill="'none'" :width="width" :height="height" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.875 5.62537L8.125 14.375L3.75 10.0004" stroke="#5E718D" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </template>
                </icon-button>
                <icon-button @click="handleCancelChanges"
                    :styles="{ type: 'Dark Grey', size: 'tiny', state: state.disabled ? 'disabled' : '' }">
                    <template v-slot="{ width, height, fill }">
                        <svg :width="width" :height="height" :fill="fill" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.625 4.375L4.375 15.625M15.625 15.625L4.375 4.375" stroke="#5E718D"
                                stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </template>
                </icon-button>
            </div>
        </div>
    </div>
    <div v-else class="template-date__readonly">
        <div class="simple-date__value__field_readonly font-paragraph-5-regular">
            <div class="to-hidden-calendar">
            </div>
            <svg @click="openCalendar" class="simple-date__value__calendar-icon__readonly" width='16px' height='16px'
                viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.125 5.875H14.875M11.75 0.875V3.375M4.25 0.875V3.375M1.75 2.125H14.25C14.5952 2.125 14.875 2.40482 14.875 2.75V15.25C14.875 15.5952 14.5952 15.875 14.25 15.875H1.75C1.40482 15.875 1.125 15.5952 1.125 15.25V2.75C1.125 2.40482 1.40482 2.125 1.75 2.125Z"
                    stroke="var(--color-state-grey-300)"
                    stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <input readonly
                type="date" class="input-date-but_readonly" :value="this.value">
        </div>
    </div>
</template>

<script>
import IconButton from '@/components/UI/buttons/icon-buttons/IconButton.vue';
import guid from '@/myLib/guid';
import { ref } from 'vue';
export default {
    name: "SimpleData",
    components: {
        IconButton,
    },
    props: {
        obj: {
            label: String,
            items: String,
            path: String,
        },
        state: {
            disabled: Boolean,
            default: false,
        }
    },
    emits: ['saveNewValue', 'eventHandler'],
    setup(props) {
        const stateInput = ref({
            isInputLoading: false,
            isInputValueCorrect: true,
            isInputInEditing: false,
        });
        let value = ref(
            props.obj.items ? new Date(props.obj.items).toLocaleDateString('sv-SE') : null
        );
        let oldValue = ref(
            props.obj.items ? new Date(props.obj.items).toLocaleDateString('sv-SE') : null
        );
        let isInputWasClicked = ref(false);
        let isCanceled = ref(false);
        const dateField = ref(null);
        const keyInput = ref('begin');
        return {
            stateInput, value, oldValue, isInputWasClicked, isCanceled, dateField,
            keyInput
        }
    },
    methods: {
        changeKey(){
            this.keyInput = guid.generateUUID();
        },
        eventHandler(from, obj, message) {
            const description = `[${Date.now()}] [LOG] [SimpleInput:${from}] [объект ${obj ? JSON.stringify(obj) : 'не обрабатывался'} сообщение: ${message ? message : 'отсутствует'}]`;
            this.$emit('eventHandler', { description: description, value: obj });
        },
        openCalendar() {
            if (!this.stateInput.isInputInEditing) {
                return;
            }
            if ("showPicker" in HTMLInputElement.prototype) {
                this.dateField.showPicker();
            }
        },
        handleSetEditMode() {
            this.dateField.focus();
            this.stateInput.isInputInEditing = true;
            this.eventHandler('handleSetEditMode', null, 'Включен режим редактирования');
        },
        handleSavingChanges() {
            this.stateInput.isInputInEditing = false;
            if(!this.value) {
                this.changeKey();
                this.value = this.oldValue;
                return;
            }
            const today = new Date;
            const maxDate = new Date;
            const minDate = new Date;
            maxDate.setFullYear(today.getFullYear()+1000);
            minDate.setFullYear(today.getFullYear()-1000);
            const currentValue = new Date(this.value);
            if(currentValue > maxDate || currentValue < minDate) {
                this.value = this.oldValue;
            }
            if (this.oldValue === this.value) return;
            this.oldValue = this.value;
            this.$emit('saveNewValue', this.value);
            const date = new Date(this.value);
            this.eventHandler('handleSavingChanges', date.toISOString(), 'Изменения отправлены на сохранение');
        },
        handleCancelChanges() {
            this.value = this.oldValue;
            this.stateInput.isInputInEditing = false;
            this.$emit('eventHandler', this.value);
            this.eventHandler('handleCancelChanges', null, 'Изменения значения отменены');
        },
        handleFocusOut(event) {
            if (this.$el.contains(event.relatedTarget)) {
                this.eventHandler('handleFocusOut', null, 'Нажатие внутри компоненты');
            } else {
                this.handleSavingChanges();
                this.eventHandler('handleFocusOut', null, 'Нажатие вне компоненты');
            }
        },
    },
}
</script>

<style scoped>
.template-date__readonly {
    height: 40px;
    width: 100%;
    max-width: 400px;
    padding: 8px;
    color: var(--color-state-grey-300);
    cursor: not-allowed;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.simple-date__value__field_readonly{
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
}
.to-hidden-calendar{
    position: absolute;
    left: 120px;
    top: 0px;
    bottom: 0px;
    right: 0;
    background-color: var(--color-base-00);
}
.simple-date__value__calendar-icon__readonly{
    min-width: 20px;
}
.input-date-but_readonly {
    overflow: hidden;
    cursor: not-allowed;
    border: none;
    outline: none;
    background: transparent;
    color: var(--color-state-grey-300);
}

.requiredToFilling {
    background-color: var(--color-error-red-25);
}

.not-required {
    background-color: var(--color-state-grey-25);
}

.template-date {
    width: 100%;
    height: fit-content;
    position: relative;
}

.simple-date {
    position: relative;
    width: 100%;
    height: 40px;
    padding: 4px 0px;
}

.simple-date-edit-buttons {
    position: absolute;
    right: 0;
    height: 32px;
    padding: 0px;
    z-index: 2001;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.simple-date-edit-buttons__buttons {
    display: flex;
    gap: 6px;
}

.simple-date__value {
    width: 100%;
    height: 100%;
    display: flex;
}

.simple-date__value__field {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
    font-size: inherit;
    color: inherit;
    font-family: inherit;
    padding: 8px;
    display: flex;
    gap: 10px;
    align-items: center;
    position: relative;
    border: 1px solid transparent;
    border-right: 0px;
}

.input-date-but {
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    padding: 1px;
    padding-left: 36px;
    z-index: 1;
    border: 0px;
    background-color: transparent;
    outline: none;
}
.input-date-but:focus{
    color: var(--color-state-grey-700);
}
.empty{
    color: transparent;
}
.not-empty{
    color: var(--color-state-grey-700);
}
.input-date-but:focus {
    background-color: var(--color-state-grey-25);
}

.input-date-but::-webkit-calendar-picker-indicator {
    display: none;
}

.input-date-but::-webkit-inner-spin-button,
.input-date-but::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input-date-but::-moz-calendar-picker-indicator {
    display: none;
}

.input-date-but::-moz-clear {
    display: none;
}

.input-date-but {
    -moz-appearance: none;
    appearance: none;
}

.simple-date__value__calendar-icon {
    z-index: 2;
    border-radius: 4px;
}

.simple-date__value:hover>.simple-date__value__field {
    border: 1px solid var(--color-state-grey-200);
    /* border-right: 0px; */
    background-color: var(--color-state-grey-25);
    border-radius: 4px;
}

.simple-date__value:hover>.simple-date__value__field>.input-date-but:read-write:not(:read-only) {
    border-radius: 4px;
}

.simple-date__value:read-only {
    border: 0px;
}

.simple-date__value__button {
    display: none;
}

.simple-date__value:hover>.simple-date__value__button {
    display: block;
}

.simple-date__value:hover>.simple-date__value__field {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.simple-date__value__field:has(.input-date-but:focus) {
    border: 1px solid var(--color-state-grey-500);
    border-radius: 4px;
    background-color: var(--color-state-grey-25);
}

.simple-date__value__field__date {
    width: 100%;
    height: 100%;
    text-decoration: none;
}

.right-icon-in-date {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
</style>