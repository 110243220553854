class IInput {
    /**
    * @param guid id карточки
    * @param path путь свойства
    */  
    constructor(guid, path){
        this.guid = String(guid);
        this.path = String(path);
    }
    getGuid(){
        return this.guid;
    }
    getPath(){
        return this.path;
    }
}
export { IInput }