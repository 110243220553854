import apiConfig from "@/config/api.config.js";
import $api from "@/http";
import { ValidationObject } from '@/myLib/validationFields';
export default class ObjectService extends ValidationObject{ //получить объекты 
    static apiPrefix = apiConfig.API_GATEWAY + '/v2' + '/Code'

    static GetLinkCode(obj){
        const allowedUrlParams = ['id', 'path'];
        const urlParamsString = ObjectService.getStringByValidKeys(allowedUrlParams, obj, '/');
        return $api.get(`${ObjectService.apiPrefix}/GetLinkCode/${urlParamsString}`);
    }
    static CheckCode(obj){
        const allowedQueryParams = ['YellowText'];
        const query = ObjectService.getObjectByValidKeys(allowedQueryParams, obj);
        const allowedUrlParams = ['id', 'path'];
        const urlParamsString = ObjectService.getStringByValidKeys(allowedUrlParams, obj, '/');
        return $api.get(`${ObjectService.apiPrefix}/CheckCode/${urlParamsString}`,
            { params: query }
        );
    }
    static GetStatusInfo(obj){
        const allowedUrlParams = ['guid', 'status'];
        const urlParamsString = ObjectService.getStringByValidKeys(allowedUrlParams, obj, '/');
        const allowedQueryParams = ['path'];
        const query = ObjectService.getObjectByValidKeys(allowedQueryParams, obj);
        return $api.get(`${ObjectService.apiPrefix}/GetStatusInfo/${urlParamsString}`,
            {   
                params: query,
                signal: obj.options.signal
            }
        )
    }
    static GetLatexCode(obj){
        const allowedUrlParams = ['id', 'path'];
        const urlParamsString = ObjectService.getStringByValidKeys(allowedUrlParams, obj, '/');
        return $api.get(`${ObjectService.apiPrefix}/GetLatexCode/${urlParamsString}`);
    }
    static PutCode(obj){ //LatexCode PageDocsTEmplate changeParam+
        const allowedUrlParams = ['id', 'path'];
        const urlParamsString = ObjectService.getStringByValidKeys(allowedUrlParams, obj, '/');
        return $api.put(`${ObjectService.apiPrefix}/PutCode/${urlParamsString}`,
            { data: obj.data }
        );
    }
}