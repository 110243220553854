<template>
    <div class="group">
        <div class="group__name">
            <div class="group__name__icon-to-fold"
                @click="handleOpenCloseGroup">
                <svg class="svg-container-fix"
                    :class="!isGroupOpen ? 'rotated': ''"
                    width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.35316 11.5131C8.1579 11.7084 7.84132 11.7084 7.64606 11.5131L2.45316 6.32023C2.13818 6.00525 2.36126 5.46667 2.80672 5.46667L13.1925 5.46667C13.638 5.46667 13.861 6.00525 13.5461 6.32023L8.35316 11.5131Z" fill="#8897AE"/>
                </svg>
            </div>
            <div class="group__name__label font-paragraph-5-semibold">
                {{ obj.label }}
            </div>
        </div>
        <div v-if="isGroupOpen"
            class="group__elements">
            <div v-for="(item) in obj.items" :key="generateUUID(item)" class="elements">
                <draw-attribute :objAttribute="item" :container="container"></draw-attribute>
            </div>
        </div>
    </div>
</template>

<script>
import DrawAttribute from '@/components/systems/systemsBlockSystemInfo/DrawAttribute.vue';
import guid from '@/myLib/guid.js';
import { ref } from 'vue';

export default {
    name: "GroupInput",
    components: {
        DrawAttribute
    },
    props: {
        obj: {
            label: String,
            items: Array,
        },
        index: Array,
        container: {
            type: Object,
            default: ()=>{}
        }
    },
    watch: {
        'obj.isOpen'(newVal){
            if(!this.isGroupOpen && newVal) this.handleOpenCloseGroup();
        }
    },
    setup(props) {
        const group = ref(null);
        let timerIdOfGroupOpening = ref(null);
        let isGroupOpen = ref(props.obj.isOpen || false);
        return {
            group, timerIdOfGroupOpening, isGroupOpen
        }
    },
    methods: {
        handleOpenCloseGroup(){
            this.isGroupOpen = !this.isGroupOpen;
            if(this.isGroupOpen){
                localStorage.setItem(`${this.$route.params.id}|${this.obj.path}|isGroupOpen`, this.isGroupOpen);
            } else {
                localStorage.removeItem(`${this.$route.params.id}|${this.obj.path}|isGroupOpen`, this.isGroupOpen);
            }
        },
        generateUUID() {
            return guid.generateUUID();
        },
    },
    mounted(){
        this.isGroupOpen = localStorage.getItem(`${this.$route.params.id}|${this.obj.path}|isGroupOpen`) || false;
        if(!this.isGroupOpen && this.obj.isOpen) this.handleOpenCloseGroup();
    }
}
</script>
<style scoped>
.rotated{
    transform: rotate(-90deg);
}
svg {
  transition: transform 0.3s;
}
.group__name{
    padding: 8px 18px;
    display: flex;
    gap: 8px;
    position: relative;
}
.group__name__label{
    color: var(--color-state-grey-600);
}
.group__elements{
    padding: 0px 44px 0px 36px;
}
</style>