import apiConfig from '@/config/api.config.js';
import ObjectService from "@/services/ObjectService";
import router from '@/router/router';
const inputsHandler = {
    api: apiConfig.API_GATEWAY,

    fetchSaveChanges: (obj) => {
        const objToSend = {
            data: obj.value,
            id: obj.systemGuid,
            path: obj.path
        }
        return ObjectService.PutValue(objToSend)
    },
/**функции управления состоянием выделение возможно вынести в отдельный класс */
    getLinkToCopyPath(currentRoute, objInput){
        let pathWithoutActiveField = window.location.href;

        if(currentRoute.params.activeField){
            pathWithoutActiveField = pathWithoutActiveField.split('/').slice(0, -1).join('/');
        }

        return pathWithoutActiveField
            + '/' + objInput.path;
    },

    handleColoring(container, currentRoute, objInput){
        this.containerIntoView(container);
        router.push({name: currentRoute.name, params: {
            ...currentRoute.params,
            activeField: null
        }});
        setTimeout(()=>{
            this.setColoring(objInput, false);
        }, 3000);
    },

    setColoring(objInput, value){
        objInput.isColoring = value;
    },

    containerIntoView(container){
        if(!container) return;

        container.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
        });
    }
};

export default inputsHandler;