<template>
    <div class="latex-statuses lss">
        <div class="lss__icon" :class="getClassFromStatus">
            <svg v-if="status.includes('Modeling')"
                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                <path d="m13.354 5.146-3.5-3.5A.5.5 0 0 0 9.5 1.5h-6a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-8a.5.5 0 0 0-.146-.354m-6.5 5.5a.501.501 0 0 1-.708.708l-1.5-1.5a.5.5 0 0 1 0-.708l1.5-1.5a.5.5 0 1 1 .708.708L5.707 9.5zm4.5-.792-1.5 1.5a.5.5 0 0 1-.708-.708L10.293 9.5 9.146 8.354a.5.5 0 1 1 .708-.708l1.5 1.5a.5.5 0 0 1 0 .708M9.5 5.5V2.75l2.75 2.75z"/>
            </svg>
            <svg v-else-if="status.includes('Generation')"
                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                <path d="M12.257 8c1.541-2.163 2.327-4.399 1.234-5.491C12.4 1.416 10.163 2.202 8 3.742 5.837 2.202 3.6 1.416 2.509 2.51 1.416 3.6 2.202 5.837 3.742 7.999c-1.54 2.164-2.326 4.4-1.233 5.492.352.352.822.509 1.369.509 1.154 0 2.655-.698 4.125-1.743C9.467 13.302 10.967 14 12.125 14c.547 0 1.018-.158 1.37-.509 1.089-1.092.303-3.328-1.238-5.491m.527-4.784c.478.477.155 2.025-1.157 3.955a19 19 0 0 0-1.325-1.474q-.701-.7-1.473-1.322c1.93-1.313 3.478-1.637 3.955-1.16m-9.568 0c.137-.139.364-.21.663-.21.739 0 1.918.431 3.29 1.369q-.77.623-1.472 1.322-.7.702-1.322 1.474c-1.313-1.93-1.637-3.478-1.16-3.955m0 9.568c-.478-.477-.154-2.025 1.159-3.955a18.8 18.8 0 0 0 2.796 2.796c-1.93 1.312-3.478 1.637-3.955 1.16M8 8.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5m4.784 4.035c-.477.478-2.025.155-3.955-1.158a19 19 0 0 0 1.473-1.324q.7-.703 1.323-1.474c1.312 1.93 1.637 3.478 1.16 3.955"/>
            </svg>
            <svg v-else-if="status.includes('Replacing')"
                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                <path  d="M6.25 7.277a.5.5 0 0 0 .25-.433v-4.5a.5.5 0 0 0-.666-.469 6.504 6.504 0 0 0-4.226 7.313.5.5 0 0 0 .742.342zM5.5 3.1v3.454L2.508 8.282C2.5 8.187 2.5 8.092 2.5 8a5.51 5.51 0 0 1 3-4.899M14.5 8a6.5 6.5 0 0 1-12.105 3.294.5.5 0 0 1 .18-.688L7.5 7.74V2a.5.5 0 0 1 .5-.5 6.5 6.5 0 0 1 5.628 3.248l.018.034c.561.98.855 2.09.854 3.218"/>
            </svg>
            <svg v-else-if="status.includes('Downloading')"
                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                <path d="M8.75 2v2a.75.75 0 0 1-1.5 0V2a.75.75 0 0 1 1.5 0M14 7.25h-2a.75.75 0 1 0 0 1.5h2a.75.75 0 1 0 0-1.5m-2.641 3.048a.751.751 0 0 0-1.063 1.063l1.415 1.414a.751.751 0 1 0 1.062-1.062zM8 11.25a.75.75 0 0 0-.75.75v2a.75.75 0 1 0 1.5 0v-2a.75.75 0 0 0-.75-.75m-3.359-.952-1.414 1.415a.751.751 0 1 0 1.062 1.062l1.415-1.414a.751.751 0 1 0-1.063-1.063M4.75 8A.75.75 0 0 0 4 7.25H2a.75.75 0 0 0 0 1.5h2A.75.75 0 0 0 4.75 8m-.463-4.773a.751.751 0 1 0-1.062 1.062l1.416 1.413a.751.751 0 0 0 1.063-1.063z"/>
            </svg>
            <svg v-else-if="status === 'Completed'"
                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                <path fill="#0A9952" d="M8 1.5A6.5 6.5 0 1 0 14.5 8 6.507 6.507 0 0 0 8 1.5m2.854 5.354-3.5 3.5a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 9.293l3.146-3.147a.5.5 0 1 1 .708.708"/>
            </svg>
            <svg v-else
                class="status-warning"
                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                <path fill="#FFC700" d="M14.8 11.756 9.334 2.264a1.548 1.548 0 0 0-2.669 0L1.2 11.756a1.47 1.47 0 0 0 0 1.482A1.52 1.52 0 0 0 2.534 14h10.931A1.52 1.52 0 0 0 15 12.497c0-.26-.07-.516-.2-.741M7.5 6.5a.5.5 0 0 1 1 0V9a.5.5 0 1 1-1 0zM8 12a.75.75 0 1 1 0-1.499A.75.75 0 0 1 8 12"/>
            </svg>
        </div>
        <div class="lss__text font-paragraph-6-medium">
            {{ statusText }}
        </div>
    </div>
</template>

<script>
export default {
    name: "LatexStatuses",
    props: {
        status: {
            type: String,
            default: ''
        },
        statusText: {
            type: String,
            default: ''
        }
    },
    computed: {
        getClassFromStatus(){
            const mapStateToClass = {
                error: 'error-fill',
                success: 'success-fill',
                default: 'default-fill'
            }
            let state = 'default';
            if(this.status.includes('Error')) state = 'error';
            if(this.status.includes('Completed')) state = 'success';
            return mapStateToClass[state];
        }
    }
}
</script>

<style scoped>
.lss{
    display: flex;
    gap: 8px;
}
.lss__text{
    white-space: nowrap;
}
.default-fill > svg > path{
    fill: var(--color-primary-blue-500);
}
.error-fill > svg > path{
    fill: var(--color-error-red-800);
}
.success-fill > svg > path{
    fill: var(--color-success-green-600);
}
.status-warning path{
    fill: var(--color-warning-yellow-500) !important;
}
.lss__icon{
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>