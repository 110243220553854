<template>
    <div class="input-latex">
        <div class="input-textarea__label block-label">
            <name-input 
                :helpMessage="changeObj.helpMessage"
                :textToCopy="getLinkToCopyPath"
                :isRequiredToFilling="changeObj.isRequiredToFilling"
                :label="changeObj.label"
                :isColoredText="changeObj.isColoring">
            </name-input>
        </div>
        <div class="input-textarea__field block-field">
            <simple-latex :key="JSON.stringify(changeObj)"
                :obj="changeObj" 
                :statusText="latexObj.getStatusText()"
                :status="latexObj.getLatexStatus()"
                @eventHandler="logEvents"
                @downloadPDF="handleDownloadPDF"
                @checkLatexStatus="handleCheckStatusLatex"
                :isLoadingPDF="isLoading">
            </simple-latex>
        </div>
    </div>
</template>

<script>
import SimpleLatex from '@/components/UI/typesInputs/SimpleLatex.vue';
import NameInput from '@/components/UI/typesInputs/NameInput.vue';

import { mapActions } from 'vuex';
import { ref } from 'vue';
import { useRoute } from 'vue-router';

import filesFromServe from '@/myLib/filesFromServe';
import inputsHandler from '@/myLib/inputsHandler.js';
import { LatexHandler } from '@/myLib/latex/LatexHandler';

export default {
    name: "LatexCode",
    components: {
        SimpleLatex,
        NameInput,
    },
    props: {
        obj: {
            label: String,
            items: String,
        },
        index: Array
    },
    setup(props) {
        const route = useRoute()
        const latexObj = ref(new LatexHandler(route.params.id, props.obj.path, props.obj.status));
        const isLoading = ref(false);

        const abortController = new AbortController();

        const changeObj = ref(props.obj);
        return {
            abortController,
            changeObj,
            latexObj, isLoading
        }
    },
    watch: {
        'changeObj.isColoring'(newValue){
            if(newValue){
                this.handleColoring();
            }
        }
    },
    computed: {
        getLinkToCopyPath(){
            return inputsHandler.getLinkToCopyPath(this.$route, this.changeObj);
        }
    },
    methods: {
        ...mapActions({
            setMessageError: 'error/setMessageError',
        }),
        logEvents(obj) {
            console.log(obj);
        },
        handleDownloadPDF(){
            this.handleCheckStatusLatex()
            .then(()=>{
                return this.downloadPDF();
            })
        },
        downloadPDF(){
            if(!this.latexObj.isValidStatusToDownloadPDF()) return;
            if(this.isLoading) return;
            this.isLoading = true;
            
            return this.latexObj.updatePDFLink()
            .then(()=>{
                const linkToDownload = this.latexObj.getLinkPDF();
                console.log(linkToDownload);
                
                if(!linkToDownload) return;//ошибка ссылка пустая
                filesFromServe.downloadFileFromFileLink(linkToDownload);
            })
            .finally(() => {
                this.isLoading = false;
            });
        },
        handleCheckStatusLatex(){
            if(this.isLoading) return;

            this.isLoading = true;
            const options = {
                signal: this.abortController.signal
            }
            return this.latexObj.handleUpdatingStatus(options)
            .finally(() => {
                this.isLoading = false;
            });
        },

        handleColoring(){
            inputsHandler.handleColoring(this.$el, this.$route, this.changeObj)
        }
    },    
    mounted() {
        if(this.changeObj.isColoring){
            this.handleColoring();
        }
    },
    unmounted(){
        this.abortController.abort();
    }
}    
</script>

<style scoped>
.input-latex {
    width: 100%;
    height: fit-content;
    display: flex;
    padding: 0px 20px 0px 10px;
    border-bottom: 1px solid var(--color-state-grey-100);
}
.block-field {
    flex: 1;
    min-width: 20px;
}
.block-label {
    flex: 1;
    max-width: 316px;
    min-width: 20px;
}
</style>