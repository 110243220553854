<template>
    <group-input :obj="getObjListConnection" :index="index"></group-input>
</template>

<script>
import GroupInput from "@/components/system-inputs/GroupInput.vue";
export default {
    components: {
        GroupInput
    },
    props: {
        obj: {
            label: String,
            items: Array,
        },
        index: Array,
        container: {
            type: Object,
            default: () => { }
        }
    },
    computed: {
        getObjListConnection() {
            return {
                "isActive": this.obj.isActive,
                "name": this.obj.name,
                "label": this.obj.label,
                "type": "Group",
                "path": "",
                "items": this.obj.items.map(el => {
                    return {
                        hasLabel: false,
                        path: '',
                        items: el.name,
                        elements: [
                            {
                                label: el.label,
                                name: el.name
                            }
                        ],
                        type: 'Connection',
                        isActive: true,
                        isEditable: false,
                        name: '',
                    }
                })
            }
        }
    }
}
</script>

<style scoped></style>