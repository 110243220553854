import apiConfig from "@/config/api.config.js";
import $api from "@/http";
import { ValidationObject } from '@/myLib/validationFields';
export default class GeneralReports extends ValidationObject{
    static apiPrefix = apiConfig.API_GATEWAY + '/v2' + '/GeneralReports'

    static GetReportsObject(){
        return $api.get(`${GeneralReports.apiPrefix}/GetReportsObject`);
    }
    static GetExcelReport(){
        return $api.get(`${GeneralReports.apiPrefix}/GetExcelReport`);
    }
}