<template>
    <div v-on:focusout="handleFocusOut" class="template-dropdown" tabindex="1">
        <div class="simple-dropdown font-paragraph-5-regular"
            :class="obj.isRequiredToFilling && !valueName ? 'requiredToFilling': 'not-required'">
            <div class="simple-dropdown__value" @click="handleSetEditMode($event)">
                <div class="simple-dropdown__value__field">
                    <input
                        :class="state.disabled ? 'input-dropdown-but_disabled': ''"
                        :readonly="!stateInput.isInputInEditing"
                        type="text" class="input-dropdown-but" :title="this.value"
                        @mouseup="handleMouseUp"
                        v-model="this.value"
                        placeholder="Введите значение"
                        ref="dropdownField"
                    >
                    <svg v-if="stateInput.isItemWasChoosed && !stateInput.isInputInEditing"
                        class="simple-dropdown__value__calendar-icon"
                        width='16px' height='16px' viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.25 0.5L7 6.75L0.75 0.5" stroke="#5E718D" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg v-else-if="stateInput.isInputInEditing"
                        class="simple-dropdown__value__calendar-icon"
                        width='16px' height='16px' viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.75 7.5L7 1.25L13.25 7.5" stroke="#5E718D" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div v-if="!stateInput.isInputInEditing && !stateInput.isItemWasChoosed"
                    class="simple-dropdown__value__button">
                    <icon-button class="right-icon-in-dropdown"
                        :styles="{ type: 'Dark Grey', size: 'tiny', state: state.disabled ? 'disabled' : '' }">
                        <template v-slot="{ width, height }">
                            <svg :width=width :height="height" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.625 5.00015L15 9.37515M7.5 16.8751H3.75C3.58424 16.8751 3.42527 16.8093 3.30806 16.6921C3.19085 16.5749 3.125 16.4159 3.125 16.2501V12.759C3.125 12.677 3.14117 12.5957 3.17258 12.5199C3.20398 12.444 3.25002 12.3751 3.30806 12.3171L12.6831 2.94209C12.8003 2.82488 12.9592 2.75903 13.125 2.75903C13.2908 2.75903 13.4497 2.82488 13.5669 2.94209L17.0581 6.43321C17.1753 6.55042 17.2411 6.70939 17.2411 6.87515C17.2411 7.04091 17.1753 7.19988 17.0581 7.31709L7.5 16.8751Z"
                                    stroke="#5E718D" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </template>
                    </icon-button>
                </div>
            </div>
            <div v-if="stateInput.isInputInEditing"
                class="simple-dropdown__list ipt-shadow-lg"
                :class="obj.rotationList==='top' ? 'simple-dropdown__list_top' : 'simple-dropdown__list_bottom'"
                >
                <div @click.prevent.stop="handleSetItem(item)"
                    v-for="item in filterElementOptions" :key="item.name"
                    class="simple-dropdown__list__item font-paragraph-6-medium">
                    {{ item.label }}
                </div>
                <div v-if="filterElementOptions.length === 0"
                    class="ipt-shadow-lg">
                    <div class="simple-dropdown__list__item font-paragraph-6-medium list-item-disabled">
                        Не нашлось результата...
                    </div>
                </div>
            </div>
        </div>
        <div v-if="stateInput.isItemWasChoosed && !stateInput.isInputInEditing" class="simple-dropdown-edit-buttons">
            <div class="simple-dropdown-edit-buttons__buttons">
                <icon-button @click="handleSavingChanges"
                    :styles="{ type: 'Dark Grey', size: 'tiny', state: state.disabled ? 'disabled' : '' }">
                    <template v-slot="{ width, height }">
                        <svg :fill="'none'" :width="width" :height="height" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.875 5.62537L8.125 14.375L3.75 10.0004" stroke="#5E718D" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </template>
                </icon-button>
                <icon-button @click="handleCancelChanges"
                    :styles="{ type: 'Dark Grey', size: 'tiny', state: state.disabled ? 'disabled' : '' }">
                    <template v-slot="{ width, height, fill }">
                        <svg :width="width" :height="height" :fill="fill" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.625 4.375L4.375 15.625M15.625 15.625L4.375 4.375" stroke="#5E718D"
                                stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </template>
                </icon-button>
            </div>
        </div>
    </div>
</template>

<script>
import IconButton from '@/components/UI/buttons/icon-buttons/IconButton.vue';
import { ref } from 'vue';
export default {
    name: "SimpleData",
    components: {
        IconButton,
    },
    props: {
        obj: {
            label: String,
            items: String,
            path: String,
            rotationList: {
                type: String,
                default: 'bottom',
            }
        },
        state: {
            disabled: Boolean,
            default: false,
        }
    },
    emits: ['saveNewValue', 'eventHandler'],
    setup(props) {
        const stateInput = ref({
            isInputLoading: false,
            isInputValueCorrect: true,
            isInputInEditing: false,
            isItemWasChoosed: false
        });
        const elementOptions = ref(props.obj.elements);
        const items = ref(props.obj.items);
        const activeItem = elementOptions.value.find(item=>item.name === items.value);
        let valueName = ref(activeItem?.name);
        const oldName = valueName.value;
        let value = ref(activeItem?.label);
        let oldValue = ref(activeItem?.label) || '';
        let isInputWasClicked = ref(false);
        let isCanceled = ref(false);
        const dropdownField = ref(null);
        return {
            stateInput, value, oldValue, isInputWasClicked, isCanceled, dropdownField,
            elementOptions, valueName, oldName,
            isItemClicked: false
        }
    },
    computed:{
        filterElementOptions(){
            return this.elementOptions.filter(
                item => (
                    item.label.toLowerCase().includes(this.value?.toLowerCase())
                )
            )
        },
    },
    methods: {
        setActiveItem(label, name){
            this.value = label;
            this.valueName = name;
        },
        eventHandler(from, obj, message){
            const description = `[${Date.now()}] [LOG] [SimpleInput:${from}] [объект ${obj ? JSON.stringify(obj) : 'не обрабатывался'} сообщение: ${message ? message : 'отсутствует'}]`;
            this.$emit('eventHandler', {description: description, value: obj});
        },
        handleSetItem(item){
            this.setActiveItem(item.label, item.name);
            this.stateInput.isItemWasChoosed = true;
            this.stateInput.isInputInEditing = false;
            this.eventHandler('handleSetItem', item, 'Выбран item');
        },
        handleSetEditMode() {
            console.log('aga');
            
            if(this.state.disabled) return;
            this.setActiveItem('', null);
            this.dropdownField.focus();
            this.stateInput.isInputInEditing = true;
            this.eventHandler('handleSetEditMode', null, 'Включен режим редактирования');
        },
        handleSavingChanges() {
            this.stateInput.isInputInEditing = false;
            this.stateInput.isItemWasChoosed = false;
            if(this.oldName === this.valueName ) {
                this.eventHandler('handleSavingChanges', this.valueName, 'Попытка сохранить старое значение');
                return; // изменений нет
            }
            if(this.valueName === null){ // ввели несуществующее значение и попытались сохранить
                this.eventHandler('handleSavingChanges', this.valueName, 'Попытка сохранить несуществующее значение');
                this.value = this.oldValue;
                this.valueName = this.oldName;
                return;
            }
            this.oldName = this.valueName;
            this.oldValue = this.value;
            // if(!this.oldName) {
            //     this.value = '';
            //     this.oldValue = '';
            // }
            this.$emit('saveNewValue', this.valueName);
            this.eventHandler('handleSavingChanges', this.valueName, 'Изменения отправлены на сохранение');
        },
        handleCancelChanges() {
            this.stateInput.isItemWasChoosed = false;
            this.setActiveItem(this.oldValue, this.oldName);
            this.stateInput.isInputInEditing = false;
            this.$emit('eventHandler', this.value);
            this.eventHandler('handleCancelChanges', null, 'Изменения значения отменены');
        },
        handleFocusOut(event) {
            if (this.$el.contains(event.relatedTarget) || event.target.classList.contains('simple-dropdown__list')) 
            {
                if(event.target.classList.contains('simple-dropdown__list')){
                    this.eventHandler('handleFocusOut', null, 'Нажатие на элемент');
                }
                this.eventHandler('handleFocusOut', null, 'Нажатие внутри компоненты');
                const inputs = event.target.getElementsByTagName('input')
                if(inputs.length === 0) {
                    event.target.focus();
                    return;
                }
                inputs[0].focus();
            } else {
                this.handleSavingChanges();
                this.eventHandler('handleFocusOut', null, 'Нажатие вне компоненты');
            }
        },
    },
}
</script>

<style scoped>
.input-dropdown-but_disabled{
    color: var(--color-state-grey-300);
    cursor: not-allowed;
}
.requiredToFilling{
    /* border: 1px solid red; */
    background-color: var(--color-error-red-25);
}
.not-required{
    /* background-color: var(--color-state-grey-25); */
}
.template-dropdown{
    width: 100%;
    height: fit-content;
    position: relative;
}
.simple-dropdown {
    position: relative;
    width: 100%;
    height: 40px;
    padding: 4px 0px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.simple-dropdown__list{
    width: 100%;
    max-height: 160px;
    overflow-y: auto;
    border-radius: 6px;
    border: 1px solid var(--color-state-grey-200);
    position: absolute;
    
    background-color: var(--color-base-00);
    z-index: 3;
    display: flex;
    flex-direction: column;
}
.simple-dropdown__list_bottom{
    top: 40px;
}
.simple-dropdown__list_top{
    bottom: 40px;
}
.simple-dropdown__list__item{
    cursor: pointer;
    min-height: 40px;
    width: 100%;
    padding: 10px 8px 10px 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid var(--color-state-grey-50);
}
.simple-dropdown__list__item:hover{
    background-color: var(--color-state-grey-25);
}
.list-item-disabled{
    cursor: not-allowed;
    background-color: var(--color-state-grey-25);
    color: var(--color-state-grey-500);
}
.simple-dropdown-edit-buttons {
    position: absolute;
    right: 0;
    height: 32px;
    padding: 0px;
    z-index: 2001;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.simple-dropdown-edit-buttons__buttons {
    display: flex;
    gap: 6px;
}

.simple-dropdown__value {
    width: 100%;
    height: 100%;
    display: flex;
}
.simple-dropdown__value__field {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
    font-size: inherit;
    color: inherit;
    font-family: inherit;
    padding: 8px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    border: 1px solid transparent;
    border-right: 0px;
}
.simple-dropdown__value__field::placeholder{
    color: var(--color-state-grey-300);
}
.input-dropdown-but{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    outline: none;
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    padding: 8px;
    /* margin-right: 25px; */
    background-color: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
    border: 0px;
}
.input-dropdown-but::placeholder{
    color: var(--color-state-grey-400);
}
.input-dropdown-but:focus{
    border-radius: 4px;
    background-color: var(--color-state-grey-25);
}
.simple-dropdown__value__calendar-icon{
    z-index: 2;
    border-radius: 4px;
}
.simple-dropdown__value:hover > .simple-dropdown__value__field{
    border: 1px solid var(--color-state-grey-200);
    /* border-right: 0px; */
    background-color: var(--color-state-grey-25);
    border-radius: 4px;
}
.simple-dropdown__value:hover > .simple-dropdown__value__field > .input-dropdown-but:read-write:not(:read-only){
    border-radius: 4px;
}
.simple-dropdown__value:read-only{
    border: 0px;
}
.simple-dropdown__value__button{
    display: none;
}
.simple-dropdown__value:hover > .simple-dropdown__value__button {
    display: block;
}
.simple-dropdown__value:hover > .simple-dropdown__value__field {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.simple-dropdown__value__field:has(.input-dropdown-but:focus){
    /* border: 1px solid var(--color-state-grey-500); */
    border-radius: 4px;
}
.simple-dropdown__value__field:has(.simple-dropdown__value__calendar-icon){
    border: 1px solid var(--color-state-grey-500);
    border-radius: 4px;
}
.simple-dropdown__value__field__dropdown {
    width: 100%;
    height: 100%;
    text-decoration: none;
}
.right-icon-in-dropdown {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
</style>