<template>
    <div class="template-latex">
        <div class="latex-panel">
            <div class="latex-panel__status">
                <latex-statuses 
                    :status="status"
                    :statusText="statusText">
                </latex-statuses>
            </div>
            <div class="latex-panel__buttons">
                <ipt-button @click="handleOpenLatexEditor"
                    :styles="{
                        type: 'Secondary Grey',
                        size: 'xsm',
                        state: ''
                    }">
                    <template #iconRight>
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.125 8.625C9.71079 8.625 9.375 8.96079 9.375 9.375C9.375 9.78921 9.71079 10.125 10.125 10.125V8.625ZM14.625 9.375H15.375C15.375 8.96079 15.0392 8.625 14.625 8.625V9.375ZM13.875 13.875C13.875 14.2892 14.2108 14.625 14.625 14.625C15.0392 14.625 15.375 14.2892 15.375 13.875H13.875ZM8.84467 14.0947C8.55178 14.3876 8.55178 14.8624 8.84467 15.1553C9.13756 15.4482 9.61244 15.4482 9.90533 15.1553L8.84467 14.0947ZM20.25 12C20.25 16.5563 16.5563 20.25 12 20.25V21.75C17.3848 21.75 21.75 17.3848 21.75 12H20.25ZM12 20.25C7.44365 20.25 3.75 16.5563 3.75 12H2.25C2.25 17.3848 6.61522 21.75 12 21.75V20.25ZM3.75 12C3.75 7.44365 7.44365 3.75 12 3.75V2.25C6.61522 2.25 2.25 6.61522 2.25 12H3.75ZM12 3.75C16.5563 3.75 20.25 7.44365 20.25 12H21.75C21.75 6.61522 17.3848 2.25 12 2.25V3.75ZM10.125 10.125H14.625V8.625H10.125V10.125ZM13.875 9.375V13.875H15.375V9.375H13.875ZM9.90533 15.1553L15.1553 9.90533L14.0947 8.84467L8.84467 14.0947L9.90533 15.1553Z" 
                            fill="var(--color-state-grey-500)"/>
                        </svg>
                    </template>
                    Редактор
                </ipt-button>
                <ipt-button @click="handleDownloadPDF"
                    :styles="{
                        type: 'Primary',
                        size: 'xsm',
                    }"
                    :isLoading="isLoadingPDF"
                    >
                    PDF
                </ipt-button>
            </div>
        </div>
    </div>
</template>

<script>
import IptButton from '@/components/UI/buttons/IptButton.vue';
import LatexStatuses from '@/components/latex-editor/UI/LatexStatuses.vue';

import { ref } from 'vue';
export default {
    name: "SimpleTextarea",
    components: {
        IptButton,
        LatexStatuses,
    },
    props: {
        obj: {
            label: String,
            items: String,
            path: String,
        },
        status: {
            type: String,
            default: ''
        },
        statusText: {
            type: String,
            default: ''
        },
        state: {
            disabled: Boolean,
            default: false,
        },
        isLoadingPDF: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['downloadPDF', 'checkLatexStatus'],
    setup(props) {
        let statusCode = ref(props.obj.status);
        return {
            statusCode,
        }
    },
    methods: {
        handleOpenLatexEditor(){
            const newLink = `${this.$router.currentRoute.value.fullPath}/latex-editor/${this.obj.path}/false`
            window.open(newLink, '_blank');
        },
        handleDownloadPDF(){
            this.$emit('downloadPDF');
        },
    },
}
</script>

<style scoped>
.template-latex{
    width: 100%;
    height: fit-content;
    position: relative;
    display: flex;
    align-items: center;
}
.latex-panel{
    width: 100%;
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    transform: translate(0px, 4px);
}
.latex-panel__status{
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 0 12px 0 9px;
}
.latex-panel__status__text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.latex-panel__buttons{
    display: flex;
    gap: 12px;
}
</style>