<template>
    <router-link :to="{ path: path }" class="text-decoration-none">
        <div class="menu-item_small"
            @click="addStylesToActiveMenu" 
            :class="$route.path.split('/')[1] == path.split('/')[1]
                ? 'menu-item_active' : ''
            ">
            <div class="menu-item__logo">
                <slot name="svgIcon" v-if="!pathToIcon">
                </slot>
                    <svg v-if="name === 'System'"
                        xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="none">
                        <path d="M7.5 3.882v3a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1m5-1h-3a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1m-6 6h-3a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1m6 0h-3a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1"/>
                    </svg>
                    <svg v-else-if="name === 'Organization'"
                        xmlns="http://www.w3.org/2000/svg" width="16" height="17" >
                        <path d="M15.5 13.382h-1v-7a.5.5 0 0 0 0-1h-3v-2a.5.5 0 0 0 0-1h-9a.5.5 0 1 0 0 1v10h-1a.5.5 0 0 0 0 1h14a.5.5 0 0 0 0-1M5 4.882h1a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1m-.5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 1 1 0 1H5a.5.5 0 0 1-.5-.5m4 5.5h-3v-3h3zm.5-5H8a.5.5 0 1 1 0-1h1a.5.5 0 1 1 0 1m0-2.5H8a.5.5 0 1 1 0-1h1a.5.5 0 1 1 0 1m4.5 7.5h-2v-7h2z"/>
                    </svg>
                    <svg v-else-if="name === 'Employee'"
                        xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="none"><path d="M10.28 12.61a.5.5 0 0 1-.42.772H.64a.5.5 0 0 1-.42-.773 6 6 0 0 1 2.952-2.357 3.75 3.75 0 1 1 4.156 0 6 6 0 0 1 2.951 2.357m5.494-.01a6 6 0 0 0-2.946-2.348 3.75 3.75 0 0 0-3.784-6.458.25.25 0 0 0-.083.375 4.74 4.74 0 0 1 .226 5.621.25.25 0 0 0 .067.346 7 7 0 0 1 1.866 1.927c.199.304.28.67.228 1.03a.25.25 0 0 0 .247.29h3.769a.5.5 0 0 0 .483-.371.51.51 0 0 0-.073-.412"/>
                    </svg>
                    <svg v-else-if="name === 'administration'" 
                        xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="none"><g clip-path="url(#a)"><path d="M5.25 7.882a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0m7.885 3.574a.513.513 0 0 0-.679.15 5 5 0 0 1-.345.429 5 5 0 0 0-1.36-1.334.25.25 0 0 0-.303.02 3.74 3.74 0 0 1-4.892 0 .25.25 0 0 0-.306-.02 5 5 0 0 0-1.362 1.332 5.48 5.48 0 0 1-1.365-3.15h.963a.51.51 0 0 0 .418-.206.5.5 0 0 0-.05-.649l-1.5-1.5a.5.5 0 0 0-.708 0l-1.5 1.5a.5.5 0 0 0 .369.854h1.004a6.5 6.5 0 0 0 11.761 3.292.5.5 0 0 0-.145-.718m2.827-3.265a.5.5 0 0 0-.462-.309h-1.019A6.5 6.5 0 0 0 2.721 4.59a.5.5 0 0 0 .812.584 5.5 5.5 0 0 1 9.944 2.708H12.5a.5.5 0 0 0-.354.854l1.5 1.5a.5.5 0 0 0 .708 0l1.5-1.5a.5.5 0 0 0 .108-.545"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 .382h16v16H0z"/></clipPath></defs>
                    </svg>
                    <svg v-else-if="name === 'sandbox'"
                        xmlns="http://www.w3.org/2000/svg" width="16" height="17"><path d="M9 6.382v-1H8a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h1v-1a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1v-1H8a1.5 1.5 0 0 1-1.5-1.5v-2h-2v.5a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v.5h2v-2a1.5 1.5 0 0 1 1.5-1.5h1v-1a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1"/>
                    </svg>
                    <svg v-else-if="name === 'factory'"
                        xmlns="http://www.w3.org/2000/svg" width="16" height="17">
                        <path d="m14.854 5.778-3.75-3.75a.5.5 0 0 0-.707 0L2.32 10.105a2.798 2.798 0 1 0 3.957 3.958L13.27 7.07l1.388-.463a.5.5 0 0 0 .196-.828m-2.012.38a.5.5 0 0 0-.196.12l-2.473 2.474c-.529.18-1.359.25-2.442-.313-.662-.346-1.26-.5-1.784-.546L10.75 3.09l2.825 2.824z"/>
                    </svg>
                    <svg v-else-if="name === 'PageGeneralReports'"
                        xmlns="http://www.w3.org/2000/svg" width="16" height="17">
                        <path  d="M15.313 7.297a1 1 0 0 0-.813-.415h-1v-1a1 1 0 0 0-1-1H8.167l-1.733-1.3c-.174-.13-.384-.2-.6-.2H2.5a1 1 0 0 0-1 1v9a.5.5 0 0 0 .5.5h11.194a.5.5 0 0 0 .474-.342l1.78-5.342a1 1 0 0 0-.136-.9m-9.48-2.915 1.867 1.4a.5.5 0 0 0 .3.1h4.5v1H4.36a1 1 0 0 0-.948.684L2.5 10.3V4.382z"/>
                    </svg>
                    <svg v-else-if="name === 'docs-templates'"
                        xmlns="http://www.w3.org/2000/svg" width="16" height="17"><path d="m13.354 5.528-3.5-3.5a.5.5 0 0 0-.354-.146h-6a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-8a.5.5 0 0 0-.146-.354M10 11.382H6a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1m0-2H6a.5.5 0 1 1 0-1h4a.5.5 0 0 1 0 1m-.5-3.5v-2.75l2.75 2.75z"/>
                    </svg>
                    <svg v-else-if="name === 'help'"
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path d="M8 1.5A6.5 6.5 0 1 0 14.5 8 6.507 6.507 0 0 0 8 1.5m-.25 3a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5m.75 7a1 1 0 0 1-1-1V8a.5.5 0 0 1 0-1 1 1 0 0 1 1 1v2.5a.5.5 0 0 1 0 1"/>
                    </svg>
                    <svg v-else-if="name === 'about'"
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path d="M15 3.5v9a.5.5 0 0 1-.5.5H10a1.5 1.5 0 0 0-1.5 1.496.49.49 0 0 1-.32.472.5.5 0 0 1-.68-.468A1.5 1.5 0 0 0 6 13H1.5a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h4a2 2 0 0 1 2 2v5.483a.51.51 0 0 0 .467.516.5.5 0 0 0 .533-.5V5a2 2 0 0 1 2-2h4a.5.5 0 0 1 .5.5"/>
                    </svg>
                    <svg v-else
                        width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 7.5V18.8077C3.00054 18.9911 3.07365 19.1669 3.20337 19.2966C3.33308 19.4264 3.50886 19.4995 3.69231 19.5H20.3333C20.5101 19.5 20.6797 19.4298 20.8047 19.3047C20.9298 19.1797 21 19.0101 21 18.8333V8.25C21 8.05109 20.921 7.86032 20.7803 7.71967C20.6397 7.57902 20.4489 7.5 20.25 7.5H3ZM3 7.5V5.25C3 5.05109 3.07902 4.86032 3.21967 4.71967C3.36032 4.57902 3.55109 4.5 3.75 4.5H8.68934C8.78783 4.5 8.88536 4.5194 8.97635 4.55709C9.06735 4.59478 9.15003 4.65003 9.21967 4.71967L12 7.5" 
                            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
            </div>
            <div class="menu-item__text font-paragraph-6-medium">
                <slot name="menuText"></slot>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "MenuItemLogo",
    props: {
        path: String,
        pathToIcon: String,
        name: String,
    },
}
</script>

<style scoped>
router-link {
    text-decoration: none;
}
.menu-item {
    box-sizing: border-box;
    cursor: pointer;
    padding: 14px 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 16px;
    border: 1px solid transparent;
    fill: var(--color-state-grey-300) !important;
}
.menu-item_small {
    padding: 6px 8px;
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    min-width: 184px;
    border: 1px solid transparent;
    color: var(--color-state-grey-900);
}
.menu-item_small:hover {
    border: 1px solid var(--color-state-grey-50);
    background-color: var(--color-state-grey-50);
    color: var(--color-primary-blue-500);
}
.menu-item_small svg{
    stroke: transparent !important;
    fill: var(--color-state-grey-300) !important;
}
.menu-item_active {
    border: 1px solid var(--color-state-grey-100);
    background-color: var(--color-base-00);
    color: var(--color-primary-blue-500);
}
.menu-item_active svg, .menu-item_small:hover svg{
    fill: var(--color-primary-blue-500) !important;
    stroke: transparent !important;
}

.menu-item__logo {
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu-item__logo__content{
    width: 16px; 
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1 / 1;
}
.menu-item__text {
    white-space: nowrap;
}
</style>