class StringHandler {
    static getStringfromUnicode(unicodeString){
        try {
            return decodeURIComponent(JSON.parse(unicodeString))
        } catch (e) {
            return unicodeString;
        }
    }
    static getStringfromUnicodeLegacy1(unicodeString){
        return unicodeString || ''
            .replace(/\\u([\d\w]{4})/gi, function (match, grp) {
                return String.fromCharCode(parseInt(grp, 16));
            });
    }
    static getStringfromUnicodeLegacy2(unicodeString){
        return unicodeString || ''
            .replace(/\\\\/g, '\\')  // Сначала заменяем \\ на \
            .replaceAll(/\\u{([\da-f]{1,6})}|\\u([\da-f]{4})/gi, (_, p1, p2) => 
                String.fromCodePoint(Number.parseInt(p1 ?? p2, 16))
            );
    }
    static copyStringToClipboard(text){
        const tempTextarea = document.createElement('textarea');
        tempTextarea.value = text;
        document.body.appendChild(tempTextarea);
        tempTextarea.select();
        document.execCommand('copy');
        document.body.removeChild(tempTextarea);
    }
}
export default StringHandler;