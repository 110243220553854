// import $api from "@/http";
import axios from "axios";
import apiConfig from "@/config/api.config.js";
import $api from "@/http";
export default class AuthService {
    static async login(login, password) {
        console.log(login, password);
        
        return axios.post(`${apiConfig.API_GATEWAY}/Auth/Login`, {login, password});
    }
    static async checkAuth() {
        return $api.get(`${apiConfig.API_GATEWAY}/Auth/CheckToken`, {
            headers: {
                accessToken: localStorage.getItem('accessToken')
            }
        });
    }
    static async getUserProfile(){
        return $api.get(`${apiConfig.API_GATEWAY}/Auth/GetUser`);
    }
    static async getRestoreCode(email){
        return axios.get(`${apiConfig.API_GATEWAY}/Auth/GetRestoreCode?email=${email}`);
    }
    static async checkRecovertCode(email, code){
        return axios.get(`${apiConfig.API_GATEWAY}/Auth/CheckRecoveryCode?email=${email}&code=${code}`);
    }
    static async setNewPassword(newpassword, email, code){
        return $api.put(`${apiConfig.API_GATEWAY}/Auth/PutPassword`,
            {
                "newPassword": newpassword,
                "email": email,
                "code": code
            }
        );
    }
}