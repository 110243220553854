class OpenCard{
    static regexpCollectionPath = /^.+\[(\d+)\]$/;

    static findObjByPathFromArray(obj, path){
        return obj.find(it=>{
            return it.path === path;
        })
    }

    static getTypeEntityByFullPathRoute(fullPath){
        return fullPath.split('/')[1];
    }

    static getObjectFromPathWithParams(obj, pathToFind){
        if(!pathToFind || !obj) return;

        const arrayPaths = pathToFind.split('.');
        let tempPath = arrayPaths[0];
        let indexCollection = null;
        let flag = true;
        let tempObj = obj;

        for(let i = 1; i < arrayPaths.length;){
            if(flag) {
                tempPath += '.' + arrayPaths[i++];
            }
            else{
                tempPath += '[' + indexCollection + ']';
                tempObj = tempObj[indexCollection];
                flag = true;
                continue;
            }
            const resRegex = tempPath.match(this.regexpCollectionPath);
            const isFindCollection = this.regexpCollectionPath.test(tempPath);
            if(isFindCollection){
                indexCollection = Number(resRegex[1]);
                tempPath = tempPath.slice(0, -(resRegex[1].length + 2));
            }
            tempObj = OpenCard.findObjByPathFromArray(tempObj, tempPath)
            if(i !== arrayPaths.length){
                tempObj.isOpen = true;
                tempObj = tempObj.items;
            } else {
                return tempObj;
            }
            if(isFindCollection){
                flag = false;
            }
        }

        return tempObj;
    }

    static getObjectFromPath(obj, pathToFind){
        if(!pathToFind || !obj) return;

        const arrayPaths = pathToFind.split('.');
        let tempPath = arrayPaths[0];
        let indexCollection = null;
        let flag = true;
        let tempObj = obj;

        for(let i = 1; i < arrayPaths.length;){
            if(flag) {
                tempPath += '.' + arrayPaths[i++];
            }
            else{
                tempPath += '[' + indexCollection + ']';
                tempObj = tempObj[indexCollection];
                flag = true;
                continue;
            }
            const resRegex = tempPath.match(this.regexpCollectionPath);
            const isFindCollection = this.regexpCollectionPath.test(tempPath);
            if(isFindCollection){
                indexCollection = Number(resRegex[1]);
                tempPath = tempPath.slice(0, -(resRegex[1].length + 2));
            }
            tempObj = OpenCard.findObjByPathFromArray(tempObj, tempPath)
            if(i !== arrayPaths.length){
                tempObj = tempObj.items;
            } else {
                return tempObj;
            }
            if(isFindCollection){
                flag = false;
            }
        }

        return tempObj;
    }
    static getAllPathsValues = (obj) => {
        const regexp = /"path": ?"([A-Z0-9[*.*\]*A-Z]*)"/mgi;

        return [...JSON.stringify(obj).matchAll(regexp)]
        .map(it=>{
            return it[1];
        });
    }
}
class TestOpenCard extends OpenCard{

    static testFunc_getObjectFromPath(objCard){
        let resTest = 'Успех'
        const paths = OpenCard.getAllPathsValues(objCard);

        for(let i = 0; i < paths.length; ++i){
            let path = null;
            let obj = null
            try{
                path = paths[i];
                obj = OpenCard.getObjectFromPath(objCard, path);
            } catch (e) {
                console.log(e, path, obj);
                resTest = 'Ошибка';
            }
        }
        
        console.log('testFunc_getObjectFromPath завершен ' + resTest);
    }
}
export {TestOpenCard as TestOpenCard, OpenCard as OpenCard};